import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db, ACCOUNTS_SEARCH_INDEX } from "../../firebase";
import withoutUndefinedProperties from "../../helpers/withoutUndefinedProperties";
import { SearchIndex } from "algoliasearch/lite";

export const PROPERTY_EQUIPMENT_ITEM_COUNT = "itemCount";
export const PROPERTY_LOCATION_COUNT = "locationCount";
export const PROPERTY_ARCHIVED_LOCATIONS_COUNT = "archivedLocationCount";
export const PROPERTY_USER_COUNT = "userCount";

let accountsWatcher: Watcher | null = null;
function stopWatchingAccounts() {
  if (accountsWatcher) {
    accountsWatcher();
    accountsWatcher = null;
  }
}

let accountsSearchIndex: SearchIndex | null = null;

let countsWatchers: WatcherCollection = {};
function stopWatchingCounts() {
  Object.values(countsWatchers).forEach(unsubscribe => unsubscribe && unsubscribe());
  countsWatchers = {};
}

let limitsWatchers: WatcherCollection = {};
function stopWatchingLimits() {
  Object.values(limitsWatchers).forEach(unsubscribe => unsubscribe());
  limitsWatchers = {};
}

let lastActivityWatchers: WatcherCollection = {};
function stopWatchingActivity() {
  Object.values(lastActivityWatchers).forEach(unsubscribe => unsubscribe && unsubscribe());
  lastActivityWatchers = {};
}

let stripeWatchers: WatcherCollection = {};
function stopWatchingStripe() {
  Object.values(stripeWatchers).forEach(unsubscribe => unsubscribe());
  stripeWatchers = {};
}

const getDefaultState = () => ({
  all: {} as Dictionary<AccountInfo>
});

const getDefaultCounts = () => ({
  archivedLocations: null as number | null,
  activeLocations: null as number | null,
  archivedEquipment: null as number | null,
  activeEquipment: null as number | null,
  equipmentMoves: null as number | null
});

const AccountStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_ACCOUNT(
      state,
      { id, accountUpdates }: { id: string; accountUpdates: Partial<AccountInfo> }
    ) {
      Vue.set(state.all, id, { ...state.all[id], ...accountUpdates });
    },
    ADD_OR_UPDATE_SEVERAL_ACCOUNTS(state, newOrUpdatedAccounts: Dictionary<AccountInfo>) {
      state.all = { ...state.all, ...newOrUpdatedAccounts };
    },
    FORGET_ACCOUNT(state, id: string) {
      Vue.delete(state.all, id);
      const countsWatcher = countsWatchers[id];
      if (countsWatcher) {
        countsWatcher();
        delete countsWatchers[id];
      }
    },
    LOGOUT(state) {
      stopWatchingAccounts();
      stopWatchingActivity();
      stopWatchingCounts();
      stopWatchingLimits();
      stopWatchingStripe();
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    watchAccounts(context): void {
      const { commit } = actionContext(context);
      console.log("watchAccounts");
      stopWatchingAccounts();
      accountsWatcher = db.collection("accounts").onSnapshot(accountList => {
        console.log(accountList);
        const accountsToAdd: Dictionary<AccountInfo> = {};

        accountList.docChanges().forEach(change => {
          const accountSnapshot = change.doc;
          const id = accountSnapshot.id;

          if (accountSnapshot.exists) {
            const account = {
              ...(accountSnapshot.data() as AccountInfo),
              id,
              exists: true,
              counts: getDefaultCounts()
            };
            commit.ADD_OR_UPDATE_ACCOUNT({ id, accountUpdates: account });
          } else {
            commit.FORGET_ACCOUNT(id);
          }
        });

        console.log("accountsToAdd", accountsToAdd);
        if (Object.keys(accountsToAdd).length > 0) {
          commit.ADD_OR_UPDATE_SEVERAL_ACCOUNTS(accountsToAdd);
        }
      }, console.error);
    },
    stopWatchingAccounts(): void {
      stopWatchingAccounts();
    },
    watchCountsForAccount(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (countsWatchers[accountId]) {
        return;
      }
      countsWatchers[accountId] = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc("counts")
        .onSnapshot(countsSnapshot => {
          const counts = countsSnapshot.data() as AccountCounts | undefined;
          commit.ADD_OR_UPDATE_ACCOUNT({ id: accountId, accountUpdates: { counts } });
        }, console.error);
    },
    watchLimitsForAccount(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (limitsWatchers[accountId]) {
        return;
      }
      limitsWatchers[accountId] = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc("limits")
        .onSnapshot(limitsSnapshot => {
          const limits = limitsSnapshot.data() as AccountLimits | undefined;
          commit.ADD_OR_UPDATE_ACCOUNT({ id: accountId, accountUpdates: { limits } });
        }, console.error);
    },
    watchLastActivityForAccount(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (lastActivityWatchers[accountId]) {
        return;
      }
      lastActivityWatchers[accountId] = this.unsubscribeFromActivityWatcher = db
        .collectionGroup("history")
        .where("account", "==", accountId)
        .orderBy("timestamp", "desc")
        .limit(1)
        .onSnapshot(snapshot => {
          snapshot.forEach(activity => {
            const mostRecentActivity = { id: activity.id, ...activity.data() } as AccountActivity;
            commit.ADD_OR_UPDATE_ACCOUNT({
              id: accountId,
              accountUpdates: { mostRecentActivity }
            });
          });
        }, console.error);
    },
    watchStripeForAccount(context, accountId: string): void {
      const { commit } = actionContext(context);
      if (stripeWatchers[accountId]) {
        return;
      }
      stripeWatchers[accountId] = db
        .collection("accounts")
        .doc(accountId)
        .collection("info")
        .doc("stripe")
        .onSnapshot(stripeSnapshot => {
          const stripe = stripeSnapshot.data() as AccountStripe | undefined;
          commit.ADD_OR_UPDATE_ACCOUNT({ id: accountId, accountUpdates: { stripe } });
        }, console.error);
    },
    async getAccountsSearchIndex(context): Promise<SearchIndex> {
      if (accountsSearchIndex) {
        return accountsSearchIndex;
      } else {
        const { rootDispatch } = actionContext(context);
        try {
          console.log("getAccountsSearchIndex", "getting new index");
          const searchClient = await rootDispatch.getSearchClient();
          const searchIndex = searchClient.initIndex(ACCOUNTS_SEARCH_INDEX);
          accountsSearchIndex = searchIndex;
          return searchIndex;
        } catch (error) {
          accountsSearchIndex = null;
          throw error;
        }
      }
    }
  },
  getters: {
    accountsByName: state =>
      Object.values(withoutUndefinedProperties(state.all)).sort((a, b) => {
        return (a.name || "No Name")
          .trimLeft()
          .localeCompare((b.name || "No Name").trimLeft(), "en", { sensitivity: "base" });
      })
  }
});

export default AccountStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, AccountStore);
