





























import Vue from "vue";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";
import { Subject } from "rxjs";

import AccountCreateModal from "@/components/AccountCreateModal.vue";
import AccountListItem from "@/components/AccountListItem.vue";
import AccountsListSearch from "@/components/AccountsListSearch.vue";
import ActionButton from "@/components/ActionButton.vue";
import ListFirestore from "@/components/ListFirestore.vue";
import SearchInput from "@/components/SearchInput.vue";
import SortByDropdown from "@/components/SortByDropdown.vue";

export default Vue.extend({
  name: "Accounts",
  components: {
    AccountCreateModal,
    AccountListItem,
    AccountsListSearch,
    ActionButton,
    ListFirestore,
    SearchInput,
    SortByDropdown
  },
  data: () => ({
    createAccountModalIsOpen: false,
    search: "",
    searchIsFocused: false,
    selectedSortOption: "mostRecentScanTime",
    onReset: new Subject()
  }),
  computed: {
    sortOptions() {
      return { mostRecentScanTime: "Last Scan", createdAt: "Date Created", name: "Name" };
    },
    sortDirection(): "asc" | "desc" {
      switch (this.selectedSortOption) {
        case "createdAt":
        case "mostRecentScanTime":
          return "desc";
        case "name":
        default:
          return "asc";
      }
    },
    showSortOption(): boolean {
      return !this.search && !this.searchIsFocused;
    }
  },
  watch: {
    selectedSortOption() {
      this.onReset.next();
    }
  },
  methods: {
    openModal(): void {
      this.createAccountModalIsOpen = true;
    },
    closeModal(): void {
      this.createAccountModalIsOpen = false;
    },
    getAccountsCollection(): FirestoreQuery {
      console.log(this.selectedSortOption, this.sortDirection);
      return db
        .collection("accounts")
        .where("disabled", "==", false)
        .orderBy(this.selectedSortOption, this.sortDirection);
    },
    // onAddedOrUpdatedItems(accountsToAdd) {
    //   this.$store.direct.commit.ADD_OR_UPDATE_SEVERAL_BONUS_PAYMENTS(bonusPaymentsToAdd);
    // },
    orderList(items: Array<unknown>): Array<unknown> {
      return orderBy(Object.values(items), this.selectedSortOption, this.sortDirection);
    },
    searchFocused(): void {
      this.searchIsFocused = true;
    },
    searchLostFocus(): void {
      this.searchIsFocused = false;
    }
  }
});
