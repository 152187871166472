


















































import Vue from "vue";

export default Vue.extend({
  name: "ActionButton",
  props: {
    circle: { type: Boolean, default: false },
    fit: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    download: { type: Boolean, default: false },
    marginTop: { type: Boolean, default: false },
    href: { type: String, default: null },
    isLoading: { type: Boolean, default: false },
    kind: { type: String, default: "primary" },
    replace: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    to: { type: String, default: null },
    type: { type: String, default: "button" }
  },
  computed: {
    cssClasses() {
      return [
        "action-button",
        "action-button--" + this.kind,
        {
          "action-button--circle": this.circle,
          "action-button--loading": this.isLoading,
          "action-button--small": this.small,
          "action-button--fit": this.fit,
          "action-button--inline": this.inline,
          "action-button--margin-top": this.marginTop
        }
      ];
    }
  },
  methods: {
    onClick(event: Event): void {
      if (this.isLoading) {
        return;
      }
      this.$emit("click", event);
    },
    getDomElementRef(): Element {
      return this.$refs.button as Element;
    }
  }
});
