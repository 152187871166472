





















import Vue, { PropType } from "vue";
import { callableFunctions } from "@/firebase";

import UserName from "@/components/UserName.vue";

export default Vue.extend({
  name: "BackupItem",
  components: {
    UserName
  },
  props: {
    backup: { type: Object as PropType<BackupDocument>, required: true }
  },
  data: () => ({
    fetchingSize: false
  }),
  computed: {
    backupRunning(): boolean {
      if (
        this.backup &&
        this.backup.createdAt &&
        new Date().valueOf() - this.backup.createdAt.toDate().valueOf() > 10 * 60 * 1000
      ) {
        return false;
      }
      return true;
    }
  },
  created() {
    if (this.backup.size === null && !this.backupRunning) {
      void this.getSizeOfBackup(this.backup.id);
    }
  },
  methods: {
    async getSizeOfBackup(backupId: string): Promise<void> {
      this.fetchingSize = true;
      try {
        const result = await callableFunctions.getSizeOfBackup({ backupId });
        console.log(backupId, result.data as number);
      } catch (error: unknown) {
        console.error(error);
      }
      this.fetchingSize = false;
    }
  }
});
