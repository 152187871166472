



































import Vue, { PropType } from "vue";
import { db, TIMESTAMP, auth } from "@/firebase";
import { isError } from "@/typings/guards";

import ActionButton from "@/components/ActionButton.vue";
import ConfigInput from "@/components/ConfigInput.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import LocationTypeIconInput from "@/components/LocationTypeIconInput.vue";
import Modal from "@/components/Modal.vue";
import pluralize from "pluralize";
import TextInput from "@/components/TextInput.vue";

export default Vue.extend({
  name: "LocationTypeEditModal",
  components: {
    ActionButton,
    ConfigInput,
    ErrorNotice,
    LocationTypeIconInput,
    Modal,
    TextInput
  },
  props: {
    accountId: { type: String, required: true },
    closeModal: { type: Function as PropType<() => void>, required: true },
    locationType: { type: Object as PropType<LocationType | null>, default: null },
    isOpen: { type: Boolean, required: true }
  },
  data: () => ({
    error: null as Error | string | null,
    icon: null as LocationTypeIcon | null,
    isSubmitting: false,
    isDeleting: false,
    options: {
      showTimeAtLocationSummary: false
    } as Omit<LocationType, "id" | "title" | "icon">,
    title: ""
  }),
  computed: {
    canBeDeleted(): boolean {
      return this.locationType?.active === 0 && this.locationType?.archived === 0;
    },
    hasChanges(): boolean {
      return (
        (this.title && this.title !== this.locationType?.title) ||
        this.icon !== (this.locationType?.icon ?? null) ||
        this.options?.showTimeAtLocationSummary !== this.locationType?.showTimeAtLocationSummary
      );
    }
  },
  watch: {
    isOpen(isOpen: boolean) {
      if (isOpen) {
        this.title = this.locationType?.title ?? "";
        this.icon = this.locationType?.icon ?? null;
        this.options.showTimeAtLocationSummary =
          this.locationType?.showTimeAtLocationSummary || false;
      }
    }
  },
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    close(): void {
      if (this.isSubmitting) {
        return;
      }
      this.closeModal();
    },
    optionLabels() {
      return {
        showTimeAtLocationSummary: {
          label: "Show Time at Location Summary",
          description:
            "This enables a summary view of how long equipment has been at the location." +
            "It can take a long time to load for shop or vehicle locations that have a long history."
        }
      };
    },
    getAccountLocationTypesRef(): CollectionReference {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("info")
        .doc("settings")
        .collection("locationTypes");
    },
    getLocationTypeRef(): DocumentReference | null {
      if (!this.locationType?.id) {
        return null;
      }
      return this.getAccountLocationTypesRef().doc(this.locationType.id);
    },
    async onSubmit(): Promise<void> {
      if (this.hasChanges && !this.isSubmitting && !this.isDeleting) {
        this.isSubmitting = true;
        const changes: Omit<LocationType, "id"> = {
          // Use the existing title if empty
          title: this.title.trim(), // || (this.status?.title.trim() ?? ""),
          icon: this.icon ?? "home",
          ...this.options
        };

        try {
          if (pluralize.isSingular(changes.title)) {
            changes.title = pluralize(changes.title);
          }
          const isEditing = !!this.locationType;
          if (this.locationType) {
            // Edit
            await this.updateLocationType(changes);
          } else {
            // Create
            await this.createLocationType(changes);
          }
          const msg = `Successfully ${isEditing ? "Updated" : "Created"} Location Type`;
          this.$toasted.show(msg, {
            type: "success",
            duration: 4000
          });
          this.closeModal();
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.isSubmitting = false;
      }
    },
    async deleteLocationType(): Promise<void> {
      if (this.canBeDeleted && !this.isSubmitting && !this.isDeleting) {
        this.isDeleting = true;
        try {
          if (!this.locationType) {
            throw new Error("No location type to delete");
          }
          if (this.locationType.active !== 0) {
            throw new Error(
              `Location Type '${this.locationType.title}' can't be deleted because there are ${this.locationType.active} active locations`
            );
          }
          if (this.locationType.archived !== 0) {
            throw new Error(
              `Location Type '${this.locationType.title}' can't be deleted because there are ${this.locationType.archived} active locations`
            );
          }

          await this.getLocationTypeRef()?.delete();
          this.closeModal();
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.isDeleting = false;
      }
    },
    async createLocationType(locationTypeDetails: Omit<LocationType, "id">): Promise<void> {
      const currentUserId = auth().currentUser?.uid;
      if (!currentUserId) throw new Error("Must be signed in");
      const newLocationTypeData = {
        ...locationTypeDetails,
        createdAt: TIMESTAMP,
        createdBy: currentUserId,
        active: 0,
        archived: 0
      };
      await this.getAccountLocationTypesRef().add(newLocationTypeData);
    },
    async updateLocationType(updates: Partial<LocationType>): Promise<void> {
      if (updates.active) {
        throw new Error("You can't modify the active property of a locationType");
      }
      if (updates.archived) {
        throw new Error("You can't modify the archived property of a locationType");
      }
      const ref = this.getLocationTypeRef();
      if (!ref) {
        throw new Error("No location type to update");
      }
      await ref.update(updates);
    }
  }
});
