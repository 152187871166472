import Vue from "vue";
import "./plugins";
import Core from "./Core.vue";
import router from "./router";
import store from "./store";
import "./filters";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

Vue.config.productionTip = false;

// This object denotes the major and minor app version numbers (a la semver).
// The server expects to compare the major version to a number (i.e., is 1.5 >= 1.4)
//  when making database integrity decisions.

void store.dispatch.watchAuthenticationState();

const unsubscribe = store.original.watch(
  state => {
    return state.ui.authInfoHasLoaded;
  },
  () => {
    new Vue({
      router,
      store: store.original,
      render: h => h(Core)
    }).$mount("#app");
    unsubscribe();
  }
);
