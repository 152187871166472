


































import Vue from "vue";
import debounce from "lodash/debounce";
import type { DebouncedFunc } from "lodash";
import SearchIcon from "@/icons/Search.vue";

export default Vue.extend({
  name: "SearchInput",
  components: {
    SearchIcon
  },
  props: {
    dataCy: { type: String, default: null },
    value: { type: String, default: "" },
    placeholder: { type: String, default: "Search..." },
    type: { type: String, default: "search" },
    size: { type: Number, default: 20 },
    maxlength: { type: Number, default: 524288 },
    autofocus: { type: Boolean, default: false },
    autocomplete: { type: String, default: "" },
    label: { type: String, default: "" },
    list: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    autocapitalize: { type: String, default: "none" },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      inputValue: this.value,
      isFocused: false
    };
  },
  computed: {
    debouncedOnInput(): DebouncedFunc<() => void> {
      return debounce(() => this.onInput(), 100);
    }
  },
  watch: {
    value(newValue: string) {
      this.inputValue = newValue;
    }
  },
  methods: {
    onInput() {
      this.$emit("input", this.inputValue);
    },
    onFocus(event: Event): void {
      this.isFocused = true;
      this.$emit("focus", event);
    },
    onBlur(event: Event): void {
      this.isFocused = false;
      this.$emit("blur", event);
    },
    onChange(): void {
      this.$emit("change", this.inputValue);
    },
    onKeyDown(event: Event): void {
      this.$emit("keydown", event);
    },
    onKeyUp(event: Event): void {
      this.$emit("keyup", event);
    },
    onKeyPress(event: Event): void {
      this.$emit("keypress", event);
    },
    getScrollParent(node: Element | null): Element | null {
      if (!node) {
        return null;
      }

      if (node.scrollHeight > node.clientHeight) {
        return node;
      } else {
        return this.getScrollParent(node.parentElement);
      }
    },
    focusOnTextInput(): void {
      return this.focus();
    },
    focus(): void {
      (this.$refs.input as HTMLInputElement).focus();
    }
  }
});
