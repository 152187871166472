import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { callableFunctions, db } from "../../firebase";

const getDefaultState = () => ({
  items: {} as Dictionary<Equipment.Item>
});

export interface CurrentEquipmentCounts {
  accountId: string;
  activeEquipment: number;
  activeSupplies: number;
  archivedEquipment: number;
}

const EquipmentStore = defineModule({
  state: getDefaultState(),
  mutations: {
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    getEquipmentCollectionRef(context, accountId: string): CollectionReference<Equipment.Item> {
      return db
        .collection("accounts")
        .doc(accountId)
        .collection("equipment")
        .withConverter({
          toFirestore: (data: Partial<Equipment.Item>) => {
            const copy = { ...data };
            delete copy.id;
            return copy;
          },
          fromFirestore: snap => ({
            ...(snap.data() as Equipment.Item),
            id: snap.id
          })
        });
    },
    getSupplyCollectionRef(
      context,
      accountId: string
    ): CollectionReference<EquipmentSupply.SupplyLimit> {
      return db
        .collection("accounts")
        .doc(accountId)
        .collection("equipmentSupplyLimits") as CollectionReference<EquipmentSupply.SupplyLimit>;
    },
    async getScanCount(
      context,
      { accountId, timestamp }: { accountId: string; timestamp: Timestamp }
    ): Promise<number> {
      const equipmentHistory = await db
        .collectionGroup("history")
        .where("account", "==", accountId)
        .where("timestamp", ">=", timestamp)
        .get();

      const count = equipmentHistory.docs.length;
      console.log("EQUIPMENT HISTORY", equipmentHistory.docs);
      return count;
    },
    async getCurrentEquipmentCounts(context, accountId: string): Promise<CurrentEquipmentCounts> {
      const { dispatch } = actionContext(context);

      const equipmentRef = await dispatch.getEquipmentCollectionRef(accountId);
      const supplyRef = await dispatch.getSupplyCollectionRef(accountId);

      const allSupplies: Dictionary<EquipmentSupply.SupplyLimit> = {};
      const supplySnap = await supplyRef.get();
      supplySnap.docs.forEach(doc => {
        const supply = doc.data();
        allSupplies[supply.equipmentId] = supply;
      });

      const activeEquipment: Dictionary<Equipment.Item> = {};
      const archivedEquipment: Dictionary<Equipment.Item> = {};
      const equipmentSnap = await equipmentRef.get();
      equipmentSnap.docs.forEach(doc => {
        const equipment = doc.data();
        if (equipment.pointee !== null) return;

        const hasSupply = allSupplies[equipment.id] !== undefined;

        if (equipment.isArchived) {
          if (hasSupply) delete allSupplies[equipment.id];
          archivedEquipment[equipment.id] = equipment;
          return;
        }
        if (hasSupply) return;
        activeEquipment[equipment.id] = equipment;
      });

      return {
        accountId,
        activeEquipment: Object.keys(activeEquipment).length,
        activeSupplies: Object.keys(allSupplies).length,
        archivedEquipment: Object.keys(archivedEquipment).length
      };
    },
    async convertEquipmentPointers(
      context,
      { accountId, includeNonNumerics }: { accountId: string; includeNonNumerics: boolean }
    ): Promise<void> {
      const result = await callableFunctions.convertEquipmentPointers({
        accountId,
        includeNonNumerics
      });
      const allIds = result.data as string[];
      for (const id of allIds) console.log(`Failed to convert: ${id}`);
    },
    async deleteEquipmentPointees(context, accountId: string): Promise<void> {
      await callableFunctions.deleteEquipmentPointees({ accountId });
    },
    async getNumPointees(context, accountId: string): Promise<number> {
      const result = await callableFunctions.getNumPointees({ accountId });
      return result.data as number;
    },
    async getNumPointeesWithoutQrCodes(context, accountId: string): Promise<number> {
      const result = await callableFunctions.getPointeeIdsWithoutQRCodes({ accountId });
      const allIds = result.data as string[];
      return allIds.length;
    }
  }
});

export default EquipmentStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, EquipmentStore);
