











































import Vue, { PropType } from "vue";
import { v4 as uuid } from "uuid";
import { fileSizeStringFromByteCount } from "@/filters";

import UploadIcon from "@/icons/Upload.vue";

function imageTypeFromName(imageName: string): string {
  if (imageName.endsWith("pdf")) {
    return "application/pdf";
  } else if (imageName.endsWith("png")) {
    return "image/png";
  } else if (imageName.endsWith("jpg") || imageName.endsWith("jpeg") || imageName.endsWith("jpe")) {
    return "image/jpeg";
  } else if (imageName.endsWith("bmp")) {
    return "image/bmp";
  } else if (imageName.endsWith("gif")) {
    return "image/gif";
  } else if (imageName.endsWith("jfif")) {
    return "image/pipeg";
  } else if (imageName.endsWith("svg")) {
    return "image/svg+xml";
  } else if (imageName.endsWith("tif") || imageName.endsWith("tiff")) {
    return "image/tiff";
  } else if (imageName.endsWith("ico")) {
    return "image/x-icon";
  } else {
    return "data";
  }
}

export default Vue.extend({
  name: "FileInput",
  components: { UploadIcon },
  props: {
    value: { type: Array as PropType<Array<Attachment>>, default: () => [] },
    label: { type: String, default: "" },
    capture: { type: String, default: "camera" },
    multiple: { type: Boolean, default: false },
    itemLabel: { type: String, default: "photo" },
    disabled: { type: Boolean, default: false },
    maxSize: { type: Number as PropType<number | null>, default: null } // max byte count
  },
  data: () => ({
    waitingToProcess: 0,
    processedUploaders: [] as Array<Attachment>,
    objectURLs: [] as Array<string>,
    dragState: "none",
    error: null as string | null
  }),
  computed: {
    captureValue(): string | false {
      return this.capture;
    },
    clickIdiom(): string {
      return "Click";
    },
    maxSizeString(): string | null {
      if (this.maxSize) {
        return fileSizeStringFromByteCount(this.maxSize);
      }
      return null;
    },
    userPromptString(): string {
      if (this.error) {
        return this.error;
      } else if (this.disabled) {
        return "We can't upload right now";
      } else if (this.dragState === this.dragStates.hover) {
        return `Release to add ${this.itemLabel}`;
      } else if (this.dragState === this.dragStates.invalid) {
        return "We can't upload that";
      } else if (this.value && this.value.length >= 1) {
        return `${this.clickIdiom} to add another ${this.itemLabel}`;
      } else {
        return `${this.clickIdiom} to add a ${this.itemLabel}`;
      }
    },
    accept(): string {
      switch (this.capture) {
        case "camera":
        case "camera-only":
          return "image/*";
        case "microphone":
          return "audio/*";
        case "document":
          return "application/pdf";
        default:
          return "";
      }
    },
    dragTypes(): Array<string> {
      // Return the viable drag types for the given capture semantic.
      switch (this.capture) {
        case "camera":
          return [
            "Files",
            "image/png",
            "image/jpeg",
            "image/bmp",
            "image/gif",
            "image/pipeg",
            "image/svg+xml",
            "image/tiff",
            "image/x-icon"
          ];

        case "microphone":
          return ["Files", "audio/*"];

        case "document":
          return ["Files", "application/pdf"];

        default:
          return [];
      }
    },
    dragStates() {
      return {
        none: "none",
        invalid: "invalid",
        hover: "hover",
        dropped: "dropped"
      };
    },
    dragIconStatus(): string | null {
      if (this.disabled) {
        return "disabled";
      }
      if (this.dragState === this.dragStates.invalid) {
        return "invalid";
      }
      return null;
    }
  },
  watch: {
    waitingToProcess(queueLength: number) {
      if (queueLength == 0) {
        // All images should be processed. Push them up as input!
        let uploaders: Array<Attachment> = [];
        if (this.multiple) {
          uploaders = this.value.slice();
          uploaders = uploaders.concat(this.processedUploaders);
        } else {
          uploaders = this.processedUploaders;
        }
        // console.log("processed all files", uploaders);
        this.$emit("input", uploaders);
        this.processedUploaders = [];
      }
    }
  },
  beforeDestroy() {
    this.objectURLs.forEach(url => {
      window.URL.revokeObjectURL(url);
    });
  },
  methods: {
    click(): void {
      const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;
      fileInput?.click();
    },
    reactToDragAction(newDragState: string, event: DragEvent): void {
      // console.log("Reacting to drag action", newDragState, event);
      this.error = null;
      this.dragState = newDragState;

      switch (newDragState) {
        case this.dragStates.none:
          break;

        case this.dragStates.hover:
          if (this.dragIsViable(event)) {
            event.preventDefault();
            const dataTransfer = event.dataTransfer;
            if (!dataTransfer) return;

            dataTransfer.dropEffect = "copy";
            dataTransfer.effectAllowed = "copy";
          } else {
            this.dragState = this.dragStates.invalid;
          }
          break;

        case this.dragStates.invalid:
          break;

        case this.dragStates.dropped:
          if (this.dragIsViable(event)) {
            return this.itemDropped(event);
          }
          this.dragState = this.dragStates.none;
          break;

        default:
          this.dragState = this.dragStates.none;
      }
    },
    dragIsViable(event: DragEvent): boolean {
      if (this.dragTypes.length === 0) {
        return false;
      }
      // console.log("Checking types", event.dataTransfer.types);
      for (const givenType of event.dataTransfer?.types ?? []) {
        const isValid = this.dragTypes.includes(givenType);
        if (!isValid) {
          // console.log(givenType, "is INVALID.");
          return false;
        }
      }
      return true;
    },
    itemDropped(event: DragEvent): void {
      if (this.dragState !== this.dragStates.dropped) {
        // console.log("Not dropped. State is", this.dragState);
        return;
      }
      event.preventDefault();

      const files = event.dataTransfer?.files;
      // console.log(`Dropped ${files.length} files.`, event);
      if (!files?.length) return;

      const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;
      if (!fileInput) return;

      fileInput.files = files;
      this.fileChange({ target: fileInput });
      this.dragState = this.dragStates.none;
      // console.log("Finished drop. State is", this.dragState);
    },
    fileChange(event: { target: HTMLInputElement | null }): void {
      this.error = null;
      const fileInput = event.target;

      let numberOfFilesTooLarge = 0;
      const files = Array.prototype.slice.call(fileInput?.files).filter((file: File) => {
        const fileSize = file.size;
        if (this.maxSize && fileSize > this.maxSize) {
          numberOfFilesTooLarge += 1;
          return false;
        }
        return true;
      });

      if (this.maxSize && numberOfFilesTooLarge > 0) {
        console.log(`${numberOfFilesTooLarge} files too large.`, files);
        if (numberOfFilesTooLarge == 1) {
          this.error = `That file is too large (over ${fileSizeStringFromByteCount(this.maxSize)})`;
        } else {
          this.error = `${numberOfFilesTooLarge} files are too large (over ${fileSizeStringFromByteCount(
            this.maxSize
          )})`;
        }
      }

      this.cacheFiles(files);
      if (fileInput) {
        fileInput.value = "";
      }
    },
    cacheFiles(files: Array<File>): void {
      this.waitingToProcess += files.length;
      // console.log("cacheFiles", this.waitingToProcess);

      files.forEach(file => {
        const fileName = file.name;
        const fileSize = file.size;

        if (this.maxSize && fileSize > this.maxSize) {
          // File is too large
          this.waitingToProcess -= 1;
          return;
        }

        const fileReader = new FileReader();
        const fileType = imageTypeFromName(fileName);
        const previewSrc = window.URL.createObjectURL(file);

        fileReader.onloadend = () => {
          // console.log("onloadend");
          const fileData = fileReader.result ?? "";

          // Get a unique key
          const key = uuid();
          const file = new Blob([fileData], { type: fileType });

          const fileUploaderObject: Attachment = {
            file,
            fileName,
            fileSize,
            key,
            previewSrc,
            progress: 0,
            uploadTask: null,
            state: "paused"
          };

          this.objectURLs.push(previewSrc);

          this.processedUploaders.push(fileUploaderObject);
          this.waitingToProcess -= 1;
        };

        fileReader.readAsArrayBuffer(file);
      });
    }
  }
});
