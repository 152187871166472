























































































import Vue, { PropType } from "vue";
import * as permissionLevels from "@/permissions";

import ActionButton from "./ActionButton.vue";
import Modal from "./Modal.vue";
import Radio from "./Radio.vue";
import UserName from "./UserName.vue";

export default Vue.extend({
  name: "UserChangePermissionsModal",
  components: {
    ActionButton,
    Modal,
    Radio,
    UserName
  },
  props: {
    open: { type: Boolean, default: false },
    userId: { type: String, required: true },
    closeModal: { type: Function as PropType<() => void>, required: true },
    account: { type: String, required: true },
    permissions: {
      type: Object as PropType<AccountPermissions.AgnosticPermission>,
      required: true
    }
  },
  data: () => ({
    level: null as AccountPermissions.Level | null,
    updatingPermissions: false,
    canMakeMoreSuperAdmins: true,
    canMakeMoreAdmins: true
  }),
  computed: {
    permissionLevels() {
      return permissionLevels;
    }
  },
  watch: {
    permissions: {
      immediate: true,
      handler(permissions: AccountPermissions.AgnosticPermission) {
        console.log("permissions", permissions);
        if (permissions) {
          this.level = permissions.level;
        }
      }
    }
  },
  methods: {
    async saveAndClose(): Promise<void> {
      this.updatingPermissions = true;
      const special: Array<string> = [];
      console.log("new Permissions", this.level, special);
      await this.$store.direct.dispatch.changeAccessLevel({
        userId: this.userId,
        level: this.level ?? "none",
        special,
        account: this.account
      });
      this.updatingPermissions = false;
      this.closeModal();
    }
  }
});
