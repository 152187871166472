












import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "NavigatingButton",
  props: {
    to: { type: String, default: "" },
    subTitle: { type: String, default: "" },
    number: { type: Number as PropType<number | null>, default: null },
    numberColor: { type: String, default: "" }
  }
});
