import Vue from "vue";
import Router, { Route } from "vue-router";

import { auth } from "./firebase";
import store from "./store";

import Login from "./views/Login.vue";
import EmptyRoute from "./components/EmptyRoute.vue";

const App = () => import("./App.vue");
const Home = () => import("./views/Home.vue");

const Backups = () => import("./views/Backups.vue");

const Accounts = () => import("./views/Accounts.vue");
const AccountEdit = () => import("./views/AccountEdit.vue");
const AccountDetails = () => import("./views/AccountDetails.vue");
const AccountInvitationCodes = () => import("./views/AccountInvitationCodes.vue");
const AccountLocationTypes = () => import("./views/AccountLocationTypes.vue");
const AccountUsers = () => import("./views/AccountUsers.vue");
const AccountView = () => import("./views/AccountView.vue");
const ActiveAccountsReport = () => import("./views/ActiveAccountsReport.vue");
const DisabledAccounts = () => import("./views/DisabledAccounts.vue");
const GitHashes = () => import("./views/GitHashes.vue");
const GlobalAdmins = () => import("./views/GlobalAdmins.vue");
const Signups = () => import("./views/Signups.vue");
const SignupDetail = () => import("./views/SignupDetail.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: EmptyRoute,
      redirect: "/app",
      children: [
        {
          path: "login",
          component: Login,
          meta: {
            title: () => "Login"
          }
        },
        {
          path: "logout",
          async beforeEnter(to, from, next) {
            if (auth().currentUser == null) {
              next("/login");
              return;
            }
            try {
              await auth().signOut();
              console.log("LOGGED OUT. Clearing database...");
              await store.dispatch.logout();
              next("/login");
            } catch (error: unknown) {
              console.error("Error logging out: ", error);
            }
          }
        },
        {
          path: "app",
          component: App,
          beforeEnter(to, from, next) {
            if (auth().currentUser) {
              next();
            } else {
              next("/login");
            }
          },
          children: [
            {
              path: "",
              component: Home,
              meta: {
                title: () => "Home"
              }
            },
            {
              path: "backups",
              component: Backups,
              meta: {
                title: () => "Backups"
              }
            },
            {
              path: "git-hashes",
              component: GitHashes,
              meta: {
                title: () => "Git Hashes"
              }
            },
            {
              path: "list-global-admins",
              component: GlobalAdmins,
              meta: {
                title: () => "List Global Admins"
              }
            },
            {
              path: "active-accounts-report",
              component: ActiveAccountsReport,
              meta: {
                title: () => "Active Accounts Report"
              }
            },
            {
              path: "signups",
              component: EmptyRoute,
              meta: {
                title: () => "Signups"
              },
              children: [
                {
                  path: "",
                  component: Signups,
                  meta: {
                    title: () => "List"
                  }
                },
                {
                  path: ":signupId",
                  component: SignupDetail,
                  meta: {
                    title: () => "Detail"
                  }
                }
              ]
            },
            {
              path: "accounts",
              component: EmptyRoute,
              children: [
                {
                  path: "",
                  component: Accounts,
                  meta: {
                    title: () => "Accounts"
                  }
                },
                {
                  path: "disabled",
                  component: DisabledAccounts,
                  meta: {
                    title: () => "Disabled Accounts"
                  }
                },
                {
                  path: ":accountId/edit",
                  component: AccountEdit,
                  props: true,
                  meta: {
                    title: () => "Edit Account"
                  }
                },
                {
                  path: ":accountId",
                  component: AccountView,
                  props: true,
                  meta: {
                    title: () => {
                      return "Account";
                    }
                  },
                  children: [
                    {
                      path: "",
                      component: AccountDetails
                    },
                    {
                      path: "edit",
                      component: AccountEdit,
                      props: true,
                      meta: {
                        title: () => "Edit Account"
                      }
                    },
                    {
                      path: "invitation-codes",
                      props: true,
                      component: AccountInvitationCodes,
                      meta: {
                        title: () => "Account Invitation Codes"
                      }
                    },
                    {
                      path: "location-types",
                      props: true,
                      component: AccountLocationTypes,
                      meta: {
                        title: () => "Account Location Types"
                      }
                    },
                    {
                      path: "users",
                      component: AccountUsers,
                      props: true,
                      meta: {
                        title: () => "Account Users"
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
});

interface Meta {
  title: (r: Route) => TitleObject;
}

interface TitleRetriever {
  navbar?: boolean;
  title: string | ((arg0: Route) => string);
}

type TitleObject = TitleRetriever | string | null | undefined;

router.afterEach(to => {
  Vue.nextTick(() => {
    if ((to.meta as Meta | undefined)?.title) {
      const titleObject: TitleObject = (to.meta as Meta).title(to);

      // If we got a plain string, use it for document title.
      if (typeof titleObject === "string") {
        document.title = `${titleObject} - Inventory Admin`;
        // store.commit.UPDATE_PAGE_TITLE(null);

        // If we got an object with a `navbar` key set to `true`, use it for document and navbar title.
      } else if (titleObject?.navbar === true) {
        if (typeof titleObject.title === "string") {
          document.title = `${titleObject.title} - Inventory Admin`;
          // store.commit.UPDATE_PAGE_TITLE(titleObject.title);
        } else {
          document.title = `${titleObject.title(to)} - Inventory Admin`;
        }
      }
    } else {
      document.title = "Inventory";
    }
  });
  window.scrollTo(0, 0);
});

export default router;
