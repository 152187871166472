























import Vue from "vue";
import orderBy from "lodash/orderBy";
import { Subject } from "rxjs";
import { db } from "@/firebase";

import AccountCreateModal from "@/components/AccountCreateModal.vue";
import AccountListItem from "@/components/AccountListItem.vue";
import ListFirestore from "@/components/ListFirestore.vue";
import SortByDropdown from "@/components/SortByDropdown.vue";

interface SortOptions {
  createdAt: string;
  name: string;
  mostRecentScanTime: string;
}

export default Vue.extend({
  name: "DisabledAccounts",
  components: {
    AccountCreateModal,
    AccountListItem,
    ListFirestore,
    SortByDropdown
  },
  data: () => ({
    createAccountModalIsOpen: false,
    selectedSortOption: "createdAt" as keyof SortOptions,
    onReset: new Subject()
  }),
  computed: {
    minItemHeight(): number {
      return 73;
    },
    sortOptions(): SortOptions {
      return {
        createdAt: "Date Created",
        name: "Name",
        mostRecentScanTime: "Last Scan"
      };
    },
    sortDirection(): "asc" | "desc" {
      switch (this.selectedSortOption) {
        case "createdAt":
        case "mostRecentScanTime":
          return "desc";
        case "name":
        default:
          return "asc";
      }
    }
  },
  watch: {
    selectedSortOption() {
      this.onReset.next();
    }
  },
  methods: {
    openModal(): void {
      this.createAccountModalIsOpen = true;
    },
    closeModal(): void {
      this.createAccountModalIsOpen = false;
    },
    getAccountsCollection(): FirestoreQuery {
      console.log(this.selectedSortOption, this.sortDirection);
      return db
        .collection("accounts")
        .where("disabled", "!=", false)
        .orderBy("disabled", "asc")
        .orderBy(this.selectedSortOption, this.sortDirection);
    },
    // onAddedOrUpdatedItems(accountsToAdd) {
    //   this.$store.direct.commit.ADD_OR_UPDATE_SEVERAL_BONUS_PAYMENTS(bonusPaymentsToAdd);
    // },
    orderList(items: Array<unknown>): Array<unknown> {
      return orderBy(Object.values(items), this.selectedSortOption, this.sortDirection);
    }
  }
});
