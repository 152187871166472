











import Vue from "vue";

import LoadingIndicator from "./LoadingIndicator.vue";

export default Vue.extend({
  name: "LoadingScreen",
  components: {
    LoadingIndicator
  }
});
