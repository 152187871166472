


















import Vue, { PropType } from "vue";

import UserChangePermissionsModal from "@/components/UserChangePermissionsModal.vue";

export default Vue.extend({
  name: "AccountUser",
  components: {
    UserChangePermissionsModal
  },
  props: {
    account: { type: String, required: true },
    user: {
      type: Object as PropType<AccountPermissions.AgnosticPermission & { id: string }>,
      required: true
    }
  },
  data: () => ({
    permissionChangeModalOpen: false
  }),
  computed: {
    userData(): Partial<User> {
      return this.$store.direct.state.users.items[this.user.id] ?? {};
    },
    firstName(): string | null {
      return (
        // TODO: Is this really necessary?
        this.userData.name ?? (this.userData as { firstName?: string }).firstName?.trim() ?? null
      );
    },
    lastName(): string | null {
      return (
        // TODO: Is this really necessary?
        this.userData.name ?? (this.userData as { lastName?: string }).lastName?.trim() ?? null
      );
    },
    fullName(): string {
      return this.userData.name?.trim() ?? `${this.firstName ?? ""} ${this.lastName ?? ""}`.trim();
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(user: Partial<User>) {
        if (user.id) {
          void this.$store.direct.dispatch.watchUser(user.id);
        }
      }
    }
  },
  methods: {
    openPermissionChangeModal() {
      this.permissionChangeModalOpen = true;
    },
    closePermissionChangeModal() {
      this.permissionChangeModalOpen = false;
    }
  }
});
