





import Vue from "vue";

export default Vue.extend({
  name: "InventoryShieldProduct",
  props: {
    productId: { type: String, required: true }
  },
  computed: {
    product(): Partial<Product> {
      return this.$store.direct.state.products.items[this.productId] ?? {};
    },
    productLabel(): string | null {
      console.log("Plan Name", this.product);
      return (this.product?.metadata?.label as string | undefined) ?? null;
    }
  },
  watch: {
    productId: {
      immediate: true,
      handler(productId: string) {
        void this.$store.direct.dispatch.watchProduct(productId);
      }
    }
  }
});
