



























import Vue from "vue";
export default Vue.extend({
  name: "Checkbox",
  props: {
    value: { type: Boolean, required: true },
    small: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    hasSubtitle(): boolean {
      // See https://stackoverflow.com/a/47434345
      return !!(this.$slots.subtitle || [])[0];
    }
  },
  methods: {
    onChange(event: InputEvent): void {
      const checkbox = event.target as HTMLInputElement | null;
      this.sendValue(checkbox?.checked ?? false);
    },
    sendValue(value: boolean): void {
      this.$emit("input", value);
    }
  }
});
