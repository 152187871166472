













import Vue, { PropType } from "vue";
import { db } from "@/firebase";

import InvitationCodeForm from "./InvitationCodeForm.vue";
import Modal from "./Modal.vue";

interface FormValues {
  code: string;
  permissionLevel: AccountPermissions.Level;
  remainingUses: number;
}

export default Vue.extend({
  name: "InvitationCodeEditModal",
  components: {
    InvitationCodeForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    invitationCode: { type: Object as PropType<InvitationCode>, required: true }
  },
  computed: {
    formValues(): FormValues {
      return {
        code: this.invitationCode.id,
        permissionLevel: this.invitationCode.permissionLevel,
        remainingUses: this.invitationCode.remainingUses
      };
    }
  },
  methods: {
    async editInvitationCode({ code, permissionLevel, remainingUses }: FormValues): Promise<void> {
      let updates = {
        permissionLevel,
        remainingUses
      };
      const needToRemoveOldInvitationCode = code !== this.invitationCode.id;
      if (needToRemoveOldInvitationCode) {
        const newCode = await db.collection("invitationCodes").doc(code).get();
        if (newCode.exists) {
          throw new Error("That code already exists.");
        }
        const existingCode = await db
          .collection("invitationCodes")
          .doc(this.invitationCode.id)
          .get();
        updates = {
          ...existingCode.data(),
          permissionLevel,
          remainingUses
        };
        await existingCode.ref.delete();
      }
      await db.collection("invitationCodes").doc(code).set(updates, { merge: true });
      this.closeModal();
    }
  }
});
