






















import Vue from "vue";
import { db } from "@/firebase";
import { isError } from "@/typings/guards";

import ActionButton from "@/components/ActionButton.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import TextInput from "@/components/TextInput.vue";

export default Vue.extend({
  name: "AccountEdit",
  components: {
    ActionButton,
    ErrorNotice,
    TextInput
  },
  data: () => ({
    description: "",
    name: "",
    phone: "",
    submitting: false,
    error: null as Error | string | null
  }),
  computed: {
    accountId(): string | null {
      return this.$route.params.accountId ?? null;
    },
    account(): Partial<AccountInfo> {
      if (!this.accountId) return {};
      return this.$store.direct.state.accounts.all[this.accountId] ?? {};
    }
  },
  watch: {
    account(account: Partial<AccountInfo>) {
      this.name = account.name ?? "";
      this.phone = account.phone ?? "";
      this.description = account.description ?? "";
    }
  },
  methods: {
    async createAccount(): Promise<void> {
      if (this.submitting === false && this.accountId) {
        this.submitting = true;
        this.error = null;
        try {
          if (!this.name) {
            throw new Error("The account must have a name");
          }
          await db.collection("accounts").doc(this.accountId).update({
            name: this.name,
            phone: this.phone,
            description: this.description
          });
          await this.$router.replace(`/app/accounts/${this.accountId}`);
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error.message;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.submitting = false;
      }
    }
  }
});
