import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db, auth, callableFunctions } from "../../firebase";
import algoliasearch, { SearchClient } from "algoliasearch/lite";
import router from "../../router";

const getDefaultState = () => ({
  needToLoadUserData: true,
  needToLoadPermissionData: true,
  address: null as string | null,
  displayName: null as string | null,
  email: null as string | null,
  emailVerified: null as boolean | null,
  isGlobalAdmin: false,
  permissions: null as AccountPermissions.Permission | null,
  photoURL: null as string | null,
  providerData: null as Array<firebase.default.UserInfo | null> | null,
  searchToken: null as string | null,
  searchTokenExpiration: null as number | null,
  token: null as string | null,
  uid: null as string | null
});

export type CurrentUserState = ReturnType<typeof getDefaultState>;

let loadSearchTokenFromServerPromise: Promise<string> | null = null;

let currentUserDataWatcher: Watcher | null = null;
function stopWatchingCurrentUserData() {
  if (currentUserDataWatcher) {
    currentUserDataWatcher();
    currentUserDataWatcher = null;
  }
}

let currentUserPermissionsWatcher: Watcher | null = null;
function stopWatchingCurrentUserPermissions() {
  if (currentUserPermissionsWatcher) {
    currentUserPermissionsWatcher();
    currentUserPermissionsWatcher = null;
  }
}

const CurrentUserStore = defineModule({
  state: getDefaultState(),
  mutations: {
    UPDATE_CURRENT_USER(state, user: Partial<CurrentUserState>) {
      Object.entries(user).map(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
    SIGN_IN_USER(state, user: firebase.default.User) {
      state.displayName = user.displayName;
      state.email = user.email;
      state.emailVerified = user.emailVerified;
      state.photoURL = user.photoURL;
      state.uid = user.uid;
      state.providerData = user.providerData;
    },
    SET_USER_PERMISSIONS(state, permissions: AccountPermissions.Permission) {
      state.permissions = permissions;
    },
    LOGOUT(state) {
      stopWatchingCurrentUserData();
      stopWatchingCurrentUserPermissions();
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    watchAuthenticationState(context): void {
      const { commit, dispatch, rootCommit } = actionContext(context);
      console.log("watchAuthenticationState");
      auth().onAuthStateChanged(async activeUser => {
        // If we've logged in...
        if (activeUser?.uid) {
          console.log("activeUser", activeUser);
          commit.SIGN_IN_USER(activeUser);
          try {
            const idToken = await auth().currentUser?.getIdToken(true);
            commit.UPDATE_CURRENT_USER({ token: idToken });
          } catch (error: unknown) {
            commit.UPDATE_CURRENT_USER({ token: null });
            console.error("Failed to get user token:", error);
          }

          // db.collection("users")
          //   .doc(activeUser.uid)
          //   .get()
          //   .then(async user => {
          //     const userData = user.data();
          //     if (userData) {
          //       // Try to cache the user's photoURL.
          //       const newUserInfo = {};

          //       if (
          //         (!userData.photoURL && activeUser.photoURL) ||
          //         userData.photoURL !== activeUser.photoURL
          //       ) {
          //         userData.photoURL = activeUser.photoURL;
          //         newUserInfo.photoURL = activeUser.photoURL;
          //       }

          //       dispatch("updateUserMetadata", newUserInfo);
          //       commit.UPDATE_CURRENT_USER(userData);
          //       dispatch("updateCurrentUser", userData);
          //       dispatch("checkGlobalAdminPrivileges");
          //     }
          //     dispatch("watchCurrentUserData");
          //   });

          // Logged out
        } else {
          await dispatch.logout();
          await router.push("/login");
        }
        rootCommit.AUTH_INFO_LOADED();
      });
    },
    async getCurrentUserData(context): Promise<void> {
      const { commit, dispatch } = actionContext(context);
      const currentUserId = auth().currentUser?.uid;
      if (!currentUserId) return;
      if (!auth().currentUser) {
        throw new Error("Not logged in.");
      }
      const userSnapshot = await db //
        .collection("users")
        .doc(currentUserId)
        .get();

      commit.UPDATE_CURRENT_USER({
        needToLoadUserData: false,
        ...userSnapshot.data()
      });
      await dispatch.watchCurrentUserData();
    },
    watchCurrentUserData(context): void {
      const { commit } = actionContext(context);
      const currentUserId = auth().currentUser?.uid;
      if (!currentUserId) return;

      stopWatchingCurrentUserData();
      currentUserDataWatcher = db
        .collection("users")
        .doc(currentUserId)
        .onSnapshot(currentUser => {
          // Update state with user data.
          const userData = currentUser.data() as Partial<CurrentUserState>;
          if (userData) {
            commit.UPDATE_CURRENT_USER(userData);
          }
        }, console.error);
    },
    async updateUserMetadata(context, newMetadata: CurrentUserState): Promise<void> {
      const { dispatch } = actionContext(context);
      const newProfileData: Partial<CurrentUserState> = {};
      const otherData = JSON.parse(JSON.stringify(newMetadata)) as User;

      if (newMetadata.photoURL) {
        newProfileData.photoURL = newMetadata.photoURL;
      }
      if (newMetadata.displayName) {
        newProfileData.displayName = newMetadata.displayName;
        delete (otherData as typeof newProfileData).displayName;
        otherData.name = newMetadata.displayName;
      }

      try {
        await auth().currentUser?.updateProfile(newProfileData);
        return dispatch.updateCurrentUser(otherData);
      } catch (error: unknown) {
        console.error(error);
      }
    },
    async checkGlobalAdminPrivileges(context): Promise<void> {
      const { commit, dispatch } = actionContext(context);
      try {
        const permDoc = await db
          .collection("global-admin")
          .doc("administrators")
          .collection("list")
          .doc(auth().currentUser?.uid ?? "")
          .get();
        if (permDoc.exists && permDoc.data()?.admin === true) {
          commit.UPDATE_CURRENT_USER({ isGlobalAdmin: true });
          return;
        } else {
          commit.UPDATE_CURRENT_USER({ isGlobalAdmin: false });
        }
        await dispatch.watchGlobalAdminPrivileges();
      } catch (error: unknown) {
        console.error(error);
        commit.UPDATE_CURRENT_USER({ isGlobalAdmin: false });
      }
      commit.UPDATE_CURRENT_USER({ needToLoadPermissionData: false });
    },
    watchGlobalAdminPrivileges(context): void {
      const { commit } = actionContext(context);
      const currentUserId = auth().currentUser?.uid;
      if (!currentUserId) return;

      db.collection("global-admin")
        .doc("administrators")
        .collection("list")
        .doc(currentUserId)
        .onSnapshot(
          permDoc => {
            if (permDoc.exists && permDoc.data()?.admin === true) {
              commit.UPDATE_CURRENT_USER({ isGlobalAdmin: true });
              return;
            } else {
              commit.UPDATE_CURRENT_USER({ isGlobalAdmin: false });
            }
          },
          error => {
            console.error(error);
            commit.UPDATE_CURRENT_USER({ isGlobalAdmin: false });
          }
        );
    },
    logout(context): void {
      const { commit } = actionContext(context);
      commit.LOGOUT();
    },
    signUpUser(context, user: User): Promise<void> {
      return db.collection("users").doc(user.uid).set(user);
    },
    async updateCurrentUser(context, userUpdates: User): Promise<void> {
      const { commit } = actionContext(context);
      const currentUserId = auth().currentUser?.uid;
      if (!currentUserId) throw new Error("You must be signed in to update your info.");

      await db.collection("users").doc(currentUserId).update(userUpdates);

      commit.UPDATE_CURRENT_USER(userUpdates);
    },
    async getNewSearchTokenFromServer(context): Promise<string> {
      const { commit } = actionContext(context);
      const response = await callableFunctions.getGlobalAdminSearchAuthKey();

      const expiration = new Date().getTime() + 1000 * 60 * 59;
      commit.UPDATE_CURRENT_USER({
        searchToken: response.data as string,
        searchTokenExpiration: expiration
      });
      return response.data as string;
    },
    // At most only have one request for a new token from the server
    async getSearchToken(context): Promise<string> {
      const { state, dispatch } = actionContext(context);
      if (loadSearchTokenFromServerPromise) {
        return loadSearchTokenFromServerPromise;
      } else if (state.searchToken && (state.searchTokenExpiration ?? 0) > new Date().getTime()) {
        return state.searchToken;
      } else {
        loadSearchTokenFromServerPromise = dispatch.getNewSearchTokenFromServer();
        try {
          const newToken = await loadSearchTokenFromServerPromise;
          loadSearchTokenFromServerPromise = null;
          return newToken;
        } catch (error) {
          loadSearchTokenFromServerPromise = null;
          throw error;
        }
      }
    },
    async getSearchClient(context): Promise<SearchClient> {
      const { dispatch } = actionContext(context);
      const searchKey = await dispatch.getSearchToken();
      return algoliasearch("2ZI0Q60M27", searchKey);
    }
  },
  getters: {
    currentUserHasGlobalAccess: state => state.isGlobalAdmin
  }
});

export default CurrentUserStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, CurrentUserStore);
