














import Vue from "vue";

import AddButton from "@/components/AddButton.vue";
import LocationTypeEditModal from "@/components/LocationTypeEditModal.vue";
import LocationTypeList from "@/components/LocationTypeList.vue";

export default Vue.extend({
  name: "AccountLocationTypes",
  components: {
    AddButton,
    LocationTypeEditModal,
    LocationTypeList
  },
  data: () => ({
    isCreating: false
  }),
  computed: {
    accountId(): string | null {
      return this.$route.params.accountId ?? null;
    }
  }
});
