







import Vue, { PropType } from "vue";
import { db } from "@/firebase";

import AccountForm from "./AccountForm.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "AccountEditModal",
  components: {
    AccountForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    account: { type: Object as PropType<AccountInfo>, required: true }
  },
  computed: {
    formValues(): Partial<AccountInfo> {
      return {
        name: this.account.name,
        description: this.account.description ?? "",
        phone: this.account.phone ?? "",
        email: this.account.email ?? ""
      };
    }
  },
  methods: {
    async editAccount(updates: Partial<AccountInfo>): Promise<void> {
      console.log("editAccount", updates, this.account);
      await db.collection("accounts").doc(this.account.id).update(updates);
      this.closeModal();
    }
  }
});
