
























import Vue from "vue";
import orderBy from "lodash/orderBy";
import { firestore, callableFunctions } from "@/firebase";
import { isError } from "@/typings/guards";

import ActionButton from "@/components/ActionButton.vue";
import BackupItem from "@/components/BackupItem.vue";
import ListFirestore from "@/components/ListFirestore.vue";

const tenMinutes = 10 /* min */ * 60 /* sec */ * 1000; /* ms */

export default Vue.extend({
  name: "Backups",
  components: {
    ListFirestore,
    ActionButton,
    BackupItem
  },
  data: () => ({
    isRunningBackup: false,
    backupError: null as Error | string | null,
    mostRecentBackupDate: null as Date | null
  }),
  computed: {
    backupsByLaterDate(): unknown {
      return this.$store.direct.getters.backups;
    },
    manualBackupDisabled(): boolean {
      console.log("mostRecentBackupDate", this.mostRecentBackupDate);
      if (this.mostRecentBackupDate) {
        const now = new Date();
        console.log(now.valueOf() - this.mostRecentBackupDate.valueOf(), tenMinutes);
        if (now.valueOf() - this.mostRecentBackupDate.valueOf() > tenMinutes) {
          return false;
        }
      }
      return true;
    }
  },
  methods: {
    getBackupCollection(): FirestoreQuery {
      return firestore
        .collection("global-admin")
        .doc("backups")
        .collection("backups")
        .orderBy("createdAt", "desc");
    },
    orderList(items: Array<BackupDocument>): Array<BackupDocument> {
      const itemsOrderedByCreationDate = orderBy(Object.values(items), "createdAt", "desc");
      if (itemsOrderedByCreationDate.length > 0) {
        const firstBackup: Date | null = itemsOrderedByCreationDate[0]?.createdAt.toDate() ?? null;
        console.log("first item", firstBackup);
        this.mostRecentBackupDate = firstBackup;
      }

      return itemsOrderedByCreationDate;
    },
    async runBackup(): Promise<void> {
      if (!this.isRunningBackup) {
        this.isRunningBackup = true;
        this.backupError = null;
        try {
          const response = await callableFunctions.runFirestoreBackup();
          console.log(response);
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.backupError = error.message;
          } else {
            this.backupError = JSON.stringify(error);
          }
        }
        this.isRunningBackup = false;
      }
    }
  }
});
