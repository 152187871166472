











































import Vue from "vue";

import Logo from "@/components/Logo.vue";
import InformationIcon from "@/icons/Information.vue";
import InvitationCodeIcon from "@/icons/InvitationCode.vue";
import MapLocationIcon from "@/icons/ActiveJobSite.vue";
import UsersIcon from "@/icons/Users.vue";

export default Vue.extend({
  name: "SignupDetail",
  components: {
    InformationIcon,
    InvitationCodeIcon,
    MapLocationIcon,
    Logo,
    UsersIcon
  },
  props: {
    accountId: { type: String, required: true }
  },
  computed: {
    account(): AccountInfo | null {
      return this.$store.direct.state.accounts.all[this.accountId] ?? null;
    },
    accountIsDisabled(): boolean {
      return !!this.account?.disabled;
    }
  },
  watch: {
    accountId: {
      immediate: true,
      handler(accountId: string) {
        void this.$store.direct.dispatch.watchCountsForAccount(accountId);
      }
    }
  }
});
