import Vue from "vue";
import moment from "moment";

export function toFileSize(value: number): string {
  let bytes = Number(value);
  const decimalConvention = true;

  const thresh = decimalConvention ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = decimalConvention
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return `${bytes.toFixed(1)} ${units[u]?.toString() ?? "units"}`;
}
Vue.filter("toFileSize", toFileSize);

export function relativeFromNow(value: moment.MomentInput): string {
  return moment(value).fromNow();
}
Vue.filter("relativeFromNow", relativeFromNow);

export function toTimestamp(value: moment.MomentInput): string {
  return moment(value).format("hh:mm A on dddd MMMM Do, YYYY");
}
Vue.filter("toTimestamp", toTimestamp);

export function toCompactTimestamp(value: moment.MomentInput): string {
  return moment(value).format("hh:mm A MM/DD/YY");
}
Vue.filter("toCompactTimestamp", toCompactTimestamp);

export function toDate(value: moment.MomentInput): string {
  return moment(value).format("MM/DD/YY");
}
Vue.filter("toDate", toDate);

export function toCSVDate(value: Date): string {
  return moment(value).format("YYYY-MM-D");
}
Vue.filter("toCSVDate", toCSVDate);

export function toWordyDate(value: moment.MomentInput): string {
  return moment(value).format("D MMMM, YYYY");
}
Vue.filter("toWordyDate", toWordyDate);

export function toSpelledOutDate(value: moment.MomentInput): string {
  return moment(value).format("MMMM[ ]Do,[ ]YYYY");
}
Vue.filter("toSpelledOutDate", toSpelledOutDate);

export function toPermissionLevel(value: AccountPermissions.Level): string {
  switch (value) {
    case "admin":
      return "Admin";
    case "limited":
      return "Region Only";
    case "normal":
      return "Technician";
    case "super":
      return "Super Admin";
    default:
      return "None";
  }
}
Vue.filter("toPermissionLevel", toPermissionLevel);

export function yesNo(value: unknown): string {
  return value ? "Yes" : "No";
}
Vue.filter("yesNo", yesNo);

export function capitalize(value: string): string {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter("capitalize", capitalize);

export function toFormattedNumber(value: number): string {
  const valueToDisplay = isNaN(value) ? 0 : value;
  return new Number(valueToDisplay).toLocaleString();
}
Vue.filter("toFormattedNumber", toFormattedNumber);

export function fileSizeStringFromByteCount(num: number): string {
  if (typeof num !== "number" || isNaN(num)) {
    throw new TypeError("Expected a number");
  }

  const neg = num < 0;
  const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${neg ? "-" : ""}${num} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = Number((num / Math.pow(1000, exponent)).toFixed(2));
  const unit = units[exponent] ?? "units";

  return `${neg ? "-" : ""}${num} ${unit}`;
}

Vue.filter("bytes", fileSizeStringFromByteCount);
