









































































import Vue, { PropType } from "vue";
import * as permissionLevels from "@/permissions";
import { isError } from "@/typings/guards";

import ActionButton from "./ActionButton.vue";
import NumberInput from "./NumberInput.vue";
import GetInvitationCodeLink from "./GetInvitationCodeLink.vue";
import Radio from "./Radio.vue";

interface FormValues {
  code: string;
  permissionLevel: AccountPermissions.Level;
  remainingUses: number;
}

export default Vue.extend({
  name: "InvitationCodeForm",
  components: {
    ActionButton,
    GetInvitationCodeLink,
    NumberInput,
    Radio
  },
  props: {
    type: { type: String, required: true },
    formValues: { type: Object as PropType<FormValues | null>, default: null },
    account: { type: String, required: true },
    url: { type: String as PropType<string | null>, default: null },
    onSubmit: {
      type: Function as PropType<(values: FormValues) => void | Promise<void>>,
      required: true
    }
  },
  data() {
    return {
      code: this.formValues?.code ?? "",
      permissionLevel: this.formValues?.permissionLevel ?? "normal",
      remainingUses: this.formValues?.remainingUses ?? 5,
      submitting: false,
      error: null as Error | string | null
    };
  },
  computed: {
    permissionLevels() {
      return permissionLevels;
    },
    editing(): boolean {
      return this.type === "Save";
    }
  },
  methods: {
    async createInvitationCode(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          if (!this.code) {
            throw new Error("You must enter an invitation code");
          }

          await this.onSubmit({
            code: this.code,
            permissionLevel: this.permissionLevel,
            remainingUses: this.remainingUses
          });
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.submitting = false;
      }
    }
  }
});
