

















import Vue, { PropType } from "vue";
import {
  LOCATION_TYPE_ICON_CHURCH,
  LOCATION_TYPE_ICON_FIRE_TRUCK,
  LOCATION_TYPE_ICON_FIRE_STATION,
  LOCATION_TYPE_ICON_HOME,
  LOCATION_TYPE_ICON_HOSPITAL,
  LOCATION_TYPE_ICON_LOCATION,
  LOCATION_TYPE_ICON_OFFICE,
  LOCATION_TYPE_ICON_ROOM,
  LOCATION_TYPE_ICON_SCHOOL,
  LOCATION_TYPE_ICON_SHOP,
  LOCATION_TYPE_ICON_TRUCK,
  LOCATION_TYPE_ICON_USER
} from "@/locationTypeIcons";

import LocationTypeIcon from "./LocationTypeIcon.vue";

export default Vue.extend({
  name: "LocationTypeIconInput",
  components: {
    LocationTypeIcon
  },
  props: {
    value: { type: String as PropType<string | null>, default: null },
    label: { type: String, default: "" }
  },
  computed: {
    availableLocationTypes: () => [
      LOCATION_TYPE_ICON_CHURCH,
      LOCATION_TYPE_ICON_FIRE_TRUCK,
      LOCATION_TYPE_ICON_FIRE_STATION,
      LOCATION_TYPE_ICON_HOME,
      LOCATION_TYPE_ICON_HOSPITAL,
      LOCATION_TYPE_ICON_LOCATION,
      LOCATION_TYPE_ICON_OFFICE,
      LOCATION_TYPE_ICON_ROOM,
      LOCATION_TYPE_ICON_SCHOOL,
      LOCATION_TYPE_ICON_SHOP,
      LOCATION_TYPE_ICON_TRUCK,
      LOCATION_TYPE_ICON_USER
    ]
  }
});
