import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { db } from "../../firebase";

const getDefaultState = () => ({
  items: {} as Dictionary<User>,
  numberOfUsers: 0
});

let usersToWatch: WatcherCollection = {};
function resetUserStore() {
  Object.values(usersToWatch).forEach(unsubscribe => unsubscribe && unsubscribe());
  usersToWatch = {};
}

const UserStore = defineModule({
  state: getDefaultState(),
  mutations: {
    ADD_OR_UPDATE_USER(state, { id, user }: { id: string; user: User }) {
      Vue.set(state.items, id, user);
    },
    ADD_OR_UPDATE_SEVERAL_USERS(state, newOrUpdatedUsers: Dictionary<User>) {
      Vue.set(state, "items", { ...state.items, ...newOrUpdatedUsers });
    },
    FORGET_USER(state, userToRemove: string) {
      Vue.delete(state.items, userToRemove);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
      resetUserStore();
    }
  },
  actions: {
    watchUser(context, userId: string): void {
      const { commit } = actionContext(context);
      if (usersToWatch[userId]) {
        return;
      } else {
        console.log("watchUser", userId);
        usersToWatch[userId] = db
          .collection("users")
          .doc(userId)
          .onSnapshot(userSnapshot => {
            const user = { id: userSnapshot.id, ...userSnapshot.data() } as User;
            const { id } = userSnapshot;
            commit.ADD_OR_UPDATE_USER({ id, user });
          }, console.error);
      }
    },
    unwatchUser(context, userId: string): void {
      const watcher = usersToWatch[userId];
      if (watcher) {
        watcher();
        delete usersToWatch[userId];
      }
    },
    async changeAccessLevel(
      context,
      {
        account,
        userId,
        level,
        special
      }: AccountPermissions.AgnosticPermission & { account: string; userId: string }
    ): Promise<void> {
      const update: Partial<AccountPermissions.Permission> = { uid: userId };
      if (level !== undefined) {
        update.level = level;
      }
      if (special !== undefined) {
        update.special = special;
      }
      console.log("changeAccessLevel", account, userId, update);
      try {
        await db
          .collection("accounts")
          .doc(account)
          .collection("userPermissions")
          .doc(userId)
          .update(update);
        console.log("changedAccessLevel", userId, update);
      } catch (error: unknown) {
        console.error("failed to change", userId, update, error);
      }
    }
  }
});

export default UserStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, UserStore);
