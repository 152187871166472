






































import Vue from "vue";
import { db, Timestamp } from "@/firebase";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import { toCSVDate } from "@/filters";
import { dinero, toDecimal } from "dinero.js";
import { USD } from "@dinero.js/currencies";

import ActionButton from "@/components/ActionButton.vue";
import Checkbox from "@/components/Checkbox.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import NumberInput from "@/components/NumberInput.vue";
import ReportTable from "@/components/ReportTable.vue";
import { callableFunctions } from "@/firebase";

import { errorFromUnknownError } from "@/helpers/errorFromUnknownError";

type AccountInfoActiveAccounts = Modify<
  AccountInfo,
  {
    users?: number;
    scans?: number;
  }
>;

interface ActiveAccountsResultRow {
  name?: string;
  id?: string;
  scans?: number;
  startDate?: string;
  users?: number;
  subscriptionStatus?: string | null;
  lastPaymentDate?: string | null;
  lastPayment?: string | null;
  paymentStatus?: string | null;
  paymentFailureCode?: string | null;
  paymentFailureMessage?: string | null;
  nextPaymentDate?: string | null;
  lastScanDate?: string;
}

interface PaymentEvents {
  subscriptionStatus: string | null;
  latest: {
    timestamp: number;
    amount: number;
    currency: string;
    status: string;
    failureCode: string | null;
    failureMessage: string | null;
  } | null;
  next: { timestamp: number; amount: number; currency: string } | null;
}

function secondsToCSVDate(seconds: number | undefined): string | null {
  if (typeof seconds === "undefined") {
    return null;
  }

  const date = new Date(seconds * 1000);
  return toCSVDate(date);
}

function numberToDollars(amount: number | undefined): string | null {
  if (!amount) {
    return null;
  }
  const cents = Math.round(amount * 100);
  const dollars = dinero({ amount: cents, currency: USD });

  return toDecimal(dollars);
}

export default Vue.extend({
  name: "ActiveAccountsReport",
  components: {
    ActionButton,
    Checkbox,
    ErrorNotice,
    LoadingIndicator,
    NumberInput,
    ReportTable
  },
  data: () => ({
    daysAgo: 30,
    error: null as Error | null,
    loading: false,
    reportRanAt: null as null | Date,
    rows: [] as ActiveAccountsResultRow[],
    filterOnRecentScans: false,
    showForm: true
  }),
  computed: {
    columns() {
      const columns = [
        { title: "Account", field: "name" },
        { title: "Account ID", field: "id" },
        { title: `# of Scans in the past ${this.daysAgo} days`, field: "scans" },
        { title: "Start Date", field: "startDate" },
        { title: "# of Users", field: "users" },
        { title: "Subscription Status", field: "subscriptionStatus" },
        { title: "Payment Date", field: "lastPaymentDate" },
        { title: "Payment Amount", field: "lastPayment" },
        { title: "Payment Status", field: "paymentStatus" },
        { title: "Failure Code", field: "paymentFailureCode" },
        { title: "Failure Message", field: "paymentFailureMessage" },
        { title: "Next Payment", field: "nextPaymentDate" },
        { title: "Last Scan Date", field: "lastScanDate" }
      ];
      return columns;
    },
    comparableDate(): Date {
      console.log("ComparableDate", subDays(new Date(), this.daysAgo));
      return subDays(new Date(), this.daysAgo);
    },
    comparableTimestamp(): Timestamp {
      return Timestamp.fromDate(this.comparableDate);
    },
    comparableDateFormatted(): string {
      return format(subDays(new Date(), this.daysAgo), "yyyy-MM-dd");
    },
    reportRanAtTimestamp(): string {
      if (this.reportRanAt) {
        return format(this.reportRanAt, "yyyy-MM-dd h:mm:ss a");
      }
      return "";
    }
  },
  methods: {
    async loadData(): Promise<void> {
      if (this.loading === false) {
        this.showForm = false;
        this.loading = true;
        this.error = null;
        try {
          const newRows: Array<ActiveAccountsResultRow> = [];
          const accountRef = db.collection("accounts");
          let accounts: firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>;
          if (!this.filterOnRecentScans) {
            accounts = await accountRef.where("disabled", "==", false).get();
          } else {
            accounts = await accountRef
              .where("mostRecentScanTime", ">=", this.comparableTimestamp)
              .get();
          }

          for (const account of accounts.docs) {
            const accountData = account.data() as AccountInfoActiveAccounts;

            const userCollectionRef = accountRef.doc(account.id).collection("users");
            const accountUsers = await userCollectionRef.get();
            const accountUsersLength = accountUsers.docs.length;

            const scanCount = await this.$store.direct.dispatch.getScanCount({
              accountId: account.id,
              timestamp: this.comparableTimestamp
            });

            const paymentEventsResult = await callableFunctions.getPaymentEventsReport({
              accountId: account.id
            });
            const paymentEvents = paymentEventsResult.data as PaymentEvents;

            const row: ActiveAccountsResultRow = {
              name: accountData.name,
              id: account.id,
              scans: scanCount,
              startDate: toCSVDate(accountData.createdAt.toDate()),
              users: accountUsersLength,
              subscriptionStatus: paymentEvents.subscriptionStatus || "none",
              lastPaymentDate: secondsToCSVDate(paymentEvents.latest?.timestamp),
              lastPayment: numberToDollars(paymentEvents.latest?.amount),
              paymentStatus: paymentEvents.latest?.status,
              paymentFailureCode: paymentEvents.latest?.failureCode,
              paymentFailureMessage: paymentEvents.latest?.failureMessage,
              nextPaymentDate: secondsToCSVDate(paymentEvents.next?.timestamp),
              lastScanDate: "none"
            };
            if (accountData.mostRecentScanTime) {
              row.lastScanDate = toCSVDate(accountData.mostRecentScanTime?.toDate());
            }

            newRows.push(row);

            this.rows = newRows;
          }

          this.reportRanAt = new Date();
        } catch (error) {
          console.error(error);
          this.error = errorFromUnknownError(error);
        }

        this.loading = false;
      }
    },
    runNewReport(): void {
      this.showForm = true;
      this.rows = [];
    }
  }
});
