




































































































































































































































































import Vue from "vue";
import { errorFromUnknownError } from "@/helpers/errorFromUnknownError";

import AccountEditModal from "@/components/AccountEditModal.vue";
import AccountEventModal from "@/components/AccountEventModal.vue";
import Checkbox from "@/components/Checkbox.vue";
import ActionButton from "@/components/ActionButton.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import LimitsEditModal from "@/components/LimitsEditModal.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import UserName from "@/components/UserName.vue";
import { callableFunctions } from "@/firebase";

import { CurrentEquipmentCounts } from "@/store/modules/EquipmentStore";

export default Vue.extend({
  name: "AccountDetails",
  components: {
    AccountEditModal,
    AccountEventModal,
    ActionButton,
    Checkbox,
    ErrorNotice,
    LimitsEditModal,
    LoadingIndicator,
    UserName
  },
  data: () => ({
    isFixingAccountCounts: false,
    shouldRecalculateEquipmentMoves: false,
    fixingAccountCountsError: null as Error | null,
    editAccountModalIsOpen: false,
    editLimitsModalIsOpen: false,
    eventModalIsOpen: false,
    loadingCountsFromDB: false,
    countsFromDB: null as CurrentEquipmentCounts | null,
    error: null as Error | string | null,
    numPointees: 0,
    numPointeesWithoutQRCodes: 0,
    runningPointeeUpdates: false
  }),
  computed: {
    accountId(): string | null {
      return this.$route.params.accountId ?? null;
    },
    account(): Partial<AccountInfo> {
      if (!this.accountId) return {};
      return this.$store.direct.state.accounts.all[this.accountId] ?? {};
    },
    equipmentMovesCount(): number {
      return this.account.counts?.equipmentMoves ?? 0;
    },
    countsAreLoaded(): boolean {
      return Object.keys(this.account.counts ?? {}).length > 0;
    },
    disabledInfo(): AccountEvents.DisabledInfo | false | null {
      return this.account.disabled ?? null;
    },
    accountIsDisabled(): boolean {
      return !!this.disabledInfo;
    },
    loadLimits(): AccountLimits | null {
      return this.account.limits ?? null;
    },
    stripeDetails(): AccountStripe | null {
      return this.account.stripe ?? null;
    }
  },
  mounted() {
    if (this.accountId) {
      void this.$store.direct.dispatch.watchLastActivityForAccount(this.accountId);
      void this.$store.direct.dispatch.watchLimitsForAccount(this.accountId);
      void this.$store.direct.dispatch.watchStripeForAccount(this.accountId);
    }
    void this.updatePointeeNumbers();
  },
  methods: {
    async fixAccountCounts() {
      if (!this.isFixingAccountCounts) {
        this.isFixingAccountCounts = true;
        this.fixingAccountCountsError = null;
        try {
          await callableFunctions.fixAccountCounts({
            accountId: this.accountId,
            shouldRecalculateEquipmentMovementCounts: this.shouldRecalculateEquipmentMoves
          });
        } catch (error) {
          this.fixingAccountCountsError = error as Error;
        }
        this.isFixingAccountCounts = false;
      }
    },
    async getCountsFromDatabase(): Promise<void> {
      if (!this.accountId) return;
      this.loadingCountsFromDB = true;
      this.countsFromDB = await this.$store.direct.dispatch.getCurrentEquipmentCounts(
        this.accountId
      );
      this.loadingCountsFromDB = false;
    },
    async convertPointees(includeNonNumerics: boolean): Promise<void> {
      if (!this.accountId) return;
      this.runningPointeeUpdates = true;
      try {
        await this.$store.direct.dispatch.convertEquipmentPointers({
          accountId: this.accountId,
          includeNonNumerics
        });
      } catch (error) {
        console.error(error);
        this.error = errorFromUnknownError(error);
      } finally {
        this.runningPointeeUpdates = false;
      }
      await this.updatePointeeNumbers();
    },
    async deletePointees(): Promise<void> {
      if (!this.accountId) return;
      this.runningPointeeUpdates = true;
      try {
        await this.$store.direct.dispatch.deleteEquipmentPointees(this.accountId);
      } catch (error) {
        console.error(error);
        this.error = errorFromUnknownError(error);
      } finally {
        this.runningPointeeUpdates = false;
      }
      await this.updatePointeeNumbers();
    },
    async updatePointeeNumbers(): Promise<void> {
      if (!this.accountId) return;
      this.runningPointeeUpdates = true;
      try {
        this.numPointeesWithoutQRCodes = await this.$store.direct.dispatch.getNumPointeesWithoutQrCodes(
          this.accountId
        );
        this.numPointees = await this.$store.direct.dispatch.getNumPointees(this.accountId);
      } catch (error) {
        console.error(error);
        this.error = errorFromUnknownError(error);
      } finally {
        this.runningPointeeUpdates = false;
      }
    }
  }
});
