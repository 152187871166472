


















import Vue from "vue";

import XIcon from "@/icons/X.vue";

export default Vue.extend({
  name: "Modal",
  components: {
    XIcon
  },
  props: {
    open: { type: Boolean, required: true },
    closeModal: { type: Function, required: true }
  },
  beforeDestroy() {
    this.closeModal();
  }
});
