



















import Vue from "vue";
import { db } from "@/firebase";

export default Vue.extend({
  name: "GitHashes",
  data: () => ({
    gitHashes: {} as Record<string, number>,
    unsubscribe: null as Watcher | null
  }),
  computed: {
    sortedGithashes(): Array<[string, number]> {
      return Object.entries(this.gitHashes)
        .filter(item => {
          const value = item[1];
          return value > 0;
        })
        .sort((a, b) => {
          const valueA = a[1];
          const valueB = b[1];
          return valueB - valueA;
        });
    }
  },
  mounted() {
    this.displayData();
  },
  methods: {
    displayData(): void {
      this.unsubscribe = db
        .collection("global-admin")
        .doc("runningGitHashes")
        .onSnapshot(
          snapshot => {
            this.gitHashes = snapshot.data() as Record<string, number>;
            console.log("Look Here!", this.gitHashes);
          },
          error => {
            console.error(error);
          }
        );
    }
  }
});
