







import Vue, { PropType } from "vue";
import { db } from "@/firebase";

import AccountLimitsForm from "./AccountLimitsForm.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "LimitsEditModal",
  components: {
    AccountLimitsForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function as PropType<() => void>, required: true },
    accountId: { type: String, required: true }
  },
  computed: {
    limits(): AccountLimits | undefined {
      return this.$store.direct.state.accounts.all[this.accountId]?.limits;
    },
    formValues(): AccountLimits {
      return {
        administrators: this.limits?.administrators ?? null,
        superusers: this.limits?.superusers ?? null,
        equipment: this.limits?.equipment ?? null,
        technicians: this.limits?.technicians ?? null
      };
    }
  },
  methods: {
    async editAccountLimits(updates: Partial<AccountLimits>): Promise<void> {
      console.log("editAccountLimits", updates);
      await db
        .collection("accounts")
        .doc(this.accountId)
        .collection("info")
        .doc("limits")
        .set(updates);
      this.closeModal();
    }
  }
});
