























import Vue from "vue";
import { callableFunctions } from "@/firebase";
import { isError } from "@/typings/guards";

import AccountName from "./AccountName.vue";
import ActionButton from "./ActionButton.vue";
import ErrorNotice from "./ErrorNotice.vue";
import Modal from "./Modal.vue";
import TextAreaInput from "./TextAreaInput.vue";

export default Vue.extend({
  name: "AccountEventModal",
  components: {
    AccountName,
    ActionButton,
    ErrorNotice,
    Modal,
    TextAreaInput
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function, required: true },
    // "Disable" | "Enable"
    type: { type: String, required: true },
    accountId: { type: String, required: true }
  },
  data: () => ({
    submitting: false,
    message: null as string | null,
    error: null as Error | string | null
  }),
  computed: {
    placeholder(): string {
      return this.type === "Disable"
        ? "Why you're disabling the account..."
        : "Why you're enabling the account...";
    }
  },
  methods: {
    async onSubmit(): Promise<void> {
      try {
        if (this.submitting) {
          return;
        }
        this.error = null;
        this.submitting = true;

        if (this.type === "Disable") {
          // Disable the account
          await callableFunctions.disableAccount({
            accountId: this.accountId,
            message: this.message
          });
        } else if (this.type === "Enable") {
          // Enable the account
          await callableFunctions.reenableAccount({
            accountId: this.accountId,
            message: this.message
          });
        }
        this.closeModal();
      } catch (error: unknown) {
        if (isError(error)) {
          this.error = error;
        } else {
          this.error = JSON.stringify(error);
        }
      }
      this.submitting = false;
      this.message = null;
    }
  }
});
