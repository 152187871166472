




















import Vue from "vue";
import { db } from "@/firebase";

export default Vue.extend({
  name: "PublicStats",
  data() {
    return {
      totalEquipment: null as number | null,
      totalLocations: null as number | null,
      totalEquipmentScans: null as number | null,
      unsubscribe: null as Watcher | null
    };
  },
  created() {
    this.loadPublicStats();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  },
  methods: {
    loadPublicStats(): void {
      this.unsubscribe = db
        .collection("global-admin")
        .doc("public-stats")
        .onSnapshot(
          doc => {
            const {
              totalEquipment,
              totalLocations,
              totalEquipmentScans
            } = doc.data() as PublicStats;
            this.totalEquipment = totalEquipment;
            this.totalLocations = totalLocations;
            this.totalEquipmentScans = totalEquipmentScans;
          },
          error => {
            console.error(error);
          }
        );
    }
  }
});
