



















import Vue from "vue";

export default Vue.extend({
  name: "LoadingIndicator",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  }
});
