



















import Vue, { PropType } from "vue";
import { isError } from "@/typings/guards";

import ActionButton from "@/components/ActionButton.vue";
import AddressInput from "@/components/AddressInput.vue";
import ErrorNotice from "@/components/ErrorNotice.vue";
import PhoneInput from "@/components/PhoneInput.vue";
import TextInput from "@/components/TextInput.vue";

interface FormValues {
  email?: string;
  name: string;
  address: string;
  phone: string;
}

export default Vue.extend({
  name: "AccountForm",
  components: {
    ActionButton,
    AddressInput,
    ErrorNotice,
    PhoneInput,
    TextInput
  },
  props: {
    type: { type: String, required: true },
    formValues: { type: Object as PropType<Partial<FormValues> | null>, default: null },
    onSubmit: { type: Function as PropType<(v: FormValues) => Promise<void>>, required: true }
  },
  data() {
    return {
      email: this.formValues?.email ?? "",
      name: this.formValues?.name ?? "",
      address: this.formValues?.address ?? "",
      phone: this.formValues?.phone ?? "",
      submitting: false,
      error: null as Error | string | null
    };
  },
  computed: {
    currentUserEmail(): string | null {
      return this.$store.direct.state.currentUser.email;
    }
  },
  methods: {
    async createAccount(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const valuesToSubmit: FormValues = {
            name: this.name.trim(),
            address: this.address.trim(),
            phone: this.phone.trim()
          };
          if (!valuesToSubmit.name) {
            throw new Error("You must provide a name for the new account.");
          }
          if (this.type === "Create") {
            valuesToSubmit.email = this.email.trim();
          }
          await this.onSubmit(valuesToSubmit);
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.submitting = false;
      }
    }
  }
});
