







































import Vue, { PropType } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Observer from "@/components/Observer.vue";

export interface Item extends Dictionary<unknown> {
  /** A string that uniquely identifies in its context the data described by this item. */
  id: string;
}

export default Vue.extend({
  name: "List",
  components: {
    ActionButton,
    LoadingIndicator,
    Observer
  },
  props: {
    itemName: { type: String, default: "Item" },
    fetchMoreItems: { type: Function as PropType<() => void>, required: true },
    loading: { type: Boolean, default: false },
    error: { type: Error as PropType<Error | null>, default: null },
    items: { type: Array as PropType<Item[]>, default: () => [] },
    atEnd: { type: Boolean, default: false },
    showsEndNote: { type: Boolean, default: true }
  },
  computed: {
    itemNamePlural(): string {
      return `${this.itemName}s`;
    }
  }
});
