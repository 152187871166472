







import InvitationCodeForm from "./InvitationCodeForm.vue";
import Modal from "./Modal.vue";
import Vue from "vue";

import firebase, { config, db, TIMESTAMP } from "@/firebase";

const { apiKey } = config;

interface CreateInvitationCodeParams {
  code: string;
  permissionLevel: AccountPermissions.Level;
  remainingUses: number;
}

export default Vue.extend({
  name: "InvitationCodeCreateModal",
  components: {
    InvitationCodeForm,
    Modal
  },
  props: {
    account: { type: String, required: true },
    closeModal: { type: Function, required: true },
    open: { type: Boolean, default: false }
  },
  data: () => ({
    error: null as Error | string | null
  }),
  methods: {
    async createInvitationCode({
      code,
      permissionLevel,
      remainingUses
    }: CreateInvitationCodeParams): Promise<void> {
      const existingCode = await db.collection("invitationCodes").doc(code).get();
      if (existingCode.exists) {
        throw new Error("That code already exists.");
      }
      const currentUserId = firebase.auth().currentUser?.uid;
      if (!currentUserId) {
        throw new Error("You must be signed in to create invitation codes.");
      }
      const url = await this.generateInvitationCodeLink(code);
      await db.collection("invitationCodes").doc(code).set({
        account: this.account,
        permissionLevel,
        remainingUses,
        createdBy: currentUserId,
        createdAt: TIMESTAMP,
        url
      });
      this.closeModal();
    },
    async createShortLink(longDynamicLink: string): Promise<string> {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            longDynamicLink,
            suffix: {
              option: "UNGUESSABLE"
            }
          })
        }
      );
      const body = (await response.json()) as { shortLink: string };
      console.log("createShortLinkResponse", body);
      return body.shortLink;
    },
    async generateInvitationCodeLink(code: string): Promise<string> {
      console.log("generateInvitationCodeLink", code);
      const bundleId = window.APP_INFO?.build?.basePackageName ?? "com.inventoryshield.app";
      const appStoreId = "1502458904";
      const rawParams = { invitationCode: code };
      const params = new URLSearchParams(rawParams).toString();
      const encodedLink = encodeURIComponent(`https://app.inventoryshield.com/signup?${params}`);
      // const encodedLink = encodeURIComponent(`http://localhost:8080/signup?${params}`);

      // https://firebase.google.com/docs/dynamic-links/create-manually
      const longUrl = `https://inventoryshield.page.link/?link=${encodedLink}&apn=${bundleId}&ibi=${bundleId}&isi=${appStoreId}`;
      const url = await this.createShortLink(longUrl);
      console.log("generateInvitationCodeLink", url);
      return url;
    }
  }
});
