





























import Vue, { PropType } from "vue";
import EXIF from "exif-js";
import { v4 as uuid } from "uuid";
import { isNonEmptyArray } from "@/typings/guards";

import CaptureButton from "./CameraCaptureButton.vue";
import FileInput from "./FileInput.vue";

interface ImageSize {
  imageWidth: number;
  imageHeight: number;
  resultWidth: number;
  resultHeight: number;
}

export default Vue.extend({
  name: "SquareCameraView",
  components: {
    CaptureButton,
    FileInput
  },
  props: {
    value: {
      type: Object as PropType<Attachment | null>,
      default: null
    },
    prompt: {
      type: String,
      default: "Your face here"
    },
    showsOverlay: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    error: false,
    isTakingFaceImage: false
  }),
  computed: {
    hasGetUserMedia(): boolean {
      return !!navigator.mediaDevices?.getUserMedia;
    }
  },
  watch: {
    isTakingFaceImage(isTakingImage: boolean) {
      if (!isTakingImage) {
        this.stopMediaStream();
      }
    },
    value(newValue: unknown) {
      if (newValue) {
        this.error = false;
      }
    }
  },
  beforeDestroy() {
    this.stopMediaStream();
  },
  methods: {
    handleClick(): void {
      if (this.value?.previewSrc) {
        URL.revokeObjectURL(this.value.previewSrc);
      }
      this.$emit("input", null);
    },
    async start(): Promise<void> {
      if (!this.hasGetUserMedia) {
        console.error("getUserMedia() is not supported on this browser.");
        return;
      }

      if (this.isTakingFaceImage) {
        this.isTakingFaceImage = false;
        return;
      }

      this.isTakingFaceImage = true;
      this.$emit("begin");
      await this.$nextTick();

      const video = this.$refs.lens as HTMLVideoElement;

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          window.stream = stream;
          video.srcObject = stream;
        })
        .catch(error => {
          console.error(error);
          navigator.mediaDevices;
          this.isTakingFaceImage = false;
        });
    },
    tookImageUsingFileInput(images: Array<Attachment>): void {
      if (!isNonEmptyArray(images)) return;

      const image = images[0];
      const input = this.$refs.input as HTMLElement;
      const srcURL = image.previewSrc;

      const imageSource = new Image();
      imageSource.decoding = "sync";
      imageSource.src = srcURL;

      imageSource
        .decode()
        .then(() => {
          const size = {
            imageWidth: imageSource.width,
            imageHeight: imageSource.height,
            resultWidth: input.clientWidth,
            resultHeight: input.clientWidth // make it square
          };
          console.log(image);
          const reader = new FileReader();
          reader.onloadend = () => {
            const exifData = EXIF.readFromBinaryFile(reader.result) as { Orientation: number };
            console.log("exif data:", exifData);
            this.cropAndAcceptImage(imageSource, size, exifData.Orientation);
            URL.revokeObjectURL(srcURL);
          };
          reader.readAsArrayBuffer(image.file);
        })
        .catch(error => {
          console.error("Failed to decode image", error);
        });
    },
    captureImage(): void {
      const video = this.$refs.lens as HTMLVideoElement;
      const preview = this.$refs.viewfinder as HTMLDivElement;
      const size: ImageSize = {
        imageWidth: video.videoWidth,
        imageHeight: video.videoHeight,
        resultWidth: preview.clientWidth,
        resultHeight: preview.clientHeight
      };
      this.cropAndAcceptImage(video, size);
    },
    cropAndAcceptImage(
      imageSource: CanvasImageSource,
      { imageWidth, imageHeight, resultWidth, resultHeight }: ImageSize,
      exifOrientation: number | null = null
    ): void {
      const canvas = document.createElement("canvas");
      canvas.width = resultWidth;
      canvas.height = resultHeight;

      const context = canvas.getContext("2d");
      if (!context) return;
      context.imageSmoothingEnabled = true;
      context.imageSmoothingQuality = "high";

      let fullWidth = imageWidth;
      let fullHeight = imageHeight;
      console.log(
        `Got ${imageWidth}x${imageHeight} to print into canvas ${canvas.width}x${canvas.height}`
      );

      const tmpWidth = fullWidth;
      switch (exifOrientation || null) {
        case 2:
          // horizontal flip
          context.translate(canvas.width, 0);
          context.scale(-1, 1);
          break;
        case 3:
          // 180° rotate left
          context.translate(canvas.width, canvas.height);
          context.rotate(Math.PI);
          break;
        case 4:
          // vertical flip
          context.translate(0, canvas.height);
          context.scale(1, -1);
          break;
        case 5:
          // vertical flip + 90 rotate right
          context.rotate(0.5 * Math.PI);
          context.scale(1, -1);
          fullWidth = fullHeight;
          fullHeight = tmpWidth;
          break;
        case 6:
          // 90° rotate right
          context.rotate(0.5 * Math.PI);
          context.translate(0, -canvas.height);
          fullWidth = fullHeight;
          fullHeight = tmpWidth;
          break;
        case 7:
          // horizontal flip + 90 rotate right
          context.rotate(0.5 * Math.PI);
          context.translate(canvas.width, -canvas.height);
          context.scale(-1, 1);
          fullWidth = fullHeight;
          fullHeight = tmpWidth;
          break;
        case 8:
          // 90° rotate left
          context.rotate(-0.5 * Math.PI);
          context.translate(-canvas.width, 0);
          fullWidth = fullHeight;
          fullHeight = tmpWidth;
          break;
      }

      const isPortrait = fullHeight > fullWidth;
      // In portrait, we only move the y-axis. X in landscape.
      let deltaWidth = isPortrait ? 0 : Math.abs(fullWidth - fullHeight);
      let deltaHeight = isPortrait ? Math.abs(fullWidth - fullHeight) : 0;
      let deltaX = deltaWidth / 2;
      let deltaY = deltaHeight / 2;

      console.log(
        `Drawing ${
          isPortrait ? "portrait" : "landscape"
        } image (${fullWidth}x${fullHeight}):\n(Rotated with exif ${
          exifOrientation || "{none}"
        })\nDelta x: ${deltaX}\nDelta y: ${deltaY}\nDraw portion width: ${
          fullWidth - deltaWidth
        }\nDraw portion height: ${fullHeight - deltaHeight}`
      );

      if (isPortrait) {
        // Compensate for x-y confusion after rotation.
        let tmpX = deltaX;
        deltaX = deltaY;
        deltaY = tmpX;
      }

      context.drawImage(
        // Image source:
        imageSource,

        // The part of the image to draw (image's cropped coords):
        deltaX,
        deltaY,
        fullWidth - deltaWidth,
        fullHeight - deltaHeight,

        // The frame to draw into (canvas frame):
        0,
        0,
        canvas.width,
        canvas.height
      );

      canvas.toBlob(file => {
        let newImage = {
          key: this.randomImageKey(),
          file,
          previewSrc: URL.createObjectURL(file),
          uploadTask: null,
          state: "paused", //storage.TaskState.PAUSED,
          progress: 0
        };
        this.isTakingFaceImage = false;
        this.$emit("input", newImage);
      }, "image/png");
    },
    stopMediaStream(): void {
      if (window.stream) {
        window.stream.getTracks().forEach(track => track.stop());
        window.stream = null;
      }
    },
    randomImageKey(): string {
      return uuid();
    }
  }
});
