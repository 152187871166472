











import Vue from "vue";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";

import AccountUser from "@/components/AccountUser.vue";
import ListFirestore from "@/components/ListFirestore.vue";

export default Vue.extend({
  name: "AccountUsers",
  components: {
    AccountUser,
    ListFirestore
  },
  computed: {
    accountId(): string | null {
      return this.$route.params.accountId ?? null;
    },
    account(): AccountInfo | null {
      if (!this.accountId) return null;
      return this.$store.direct.state.accounts.all[this.accountId] ?? null;
    }
  },
  methods: {
    getAccountUsers(): CollectionReference | null {
      if (!this.accountId) return null;
      return db.collection("accounts").doc(this.accountId).collection("userPermissions");
    },
    orderList(items: Array<unknown>): Array<unknown> {
      return orderBy(Object.values(items), "remainingUses", "desc");
    }
  }
});
