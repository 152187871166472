















import Vue from "vue";
import type { State } from "@/store";

import Navbar from "./components/Navbar.vue";
import Sidenav from "./components/Sidenav.vue";
import LoadingScreen from "./components/LoadingScreen.vue";
import NoAccess from "./views/NoAccess.vue";

export default Vue.extend({
  components: {
    LoadingScreen,
    Navbar,
    NoAccess,
    Sidenav
  },
  computed: {
    state(): State {
      return this.$store.direct.state;
    },
    needToLoadUserData(): boolean {
      return this.state.currentUser.needToLoadUserData;
    },
    currentUserIsGlobalAdmin(): boolean {
      return this.state.currentUser.isGlobalAdmin;
    },
    pageTitle(): string {
      return this.$store.direct.getters.pageTitle;
    },
    showsBackButton(): boolean {
      // Show back button if we're not at the root.
      return !this.$route.path.endsWith("app");
    }
  },
  watch: {
    currentUserIsGlobalAdmin(isGlobalAdmin: boolean) {
      if (isGlobalAdmin) {
        void this.$store.direct.dispatch.watchAccounts();
        void this.$store.direct.dispatch.watchBackups();
      } else {
        void this.$store.direct.dispatch.stopWatchingAccounts();
        void this.$store.direct.dispatch.stopWatchingBackups();
      }
    }
  },
  created() {
    void this.$store.direct.dispatch.getCurrentUserData();
    void this.$store.direct.dispatch.checkGlobalAdminPrivileges();
  }
});
