


















import Vue, { PropType } from "vue";

import Checkbox from "./Checkbox.vue";

interface OptionLabel {
  description: string;
  label: string;
}

export default Vue.extend({
  name: "ConfigInput",
  components: {
    Checkbox
  },
  props: {
    label: { type: String, required: true },
    optionLabels: { type: Object as PropType<Dictionary<OptionLabel>>, required: true }
  },
  data: () => ({
    options: {} as Dictionary<boolean>
  }),
  watch: {
    options() {
      this.$emit("input", this.options);
    }
  }
});
