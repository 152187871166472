























import Vue from "vue";

import DropdownIcon from "@/icons/Dropdown.vue";

export default Vue.extend({
  name: "SortByDropdown",
  components: {
    DropdownIcon
  },
  props: {
    value: { type: String, required: true },
    options: { type: Object, default: () => ({}) },
    label: { type: String, default: "Sort By:" }
  },
  data() {
    return {
      selectValue: this.value
    };
  },
  watch: {
    value(newValue: string) {
      this.selectValue = newValue;
    }
  },
  methods: {
    focus(): void {
      const select = this.$refs.select as HTMLSelectElement;
      select.click();
    },
    onChange(event: Event): void {
      const target = event.target as HTMLSelectElement | null;
      this.$emit("input", target?.value);
    },
    onFocus(event: Event): void {
      this.$emit("focus", event);
    },
    onBlur(event: Event): void {
      this.$emit("blur", event);
    }
  }
});
