






































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AccountListItem",
  props: {
    account: { type: Object as PropType<AccountInfo>, required: true },
    selected: { type: Boolean, default: false }
  },
  computed: {
    accounts(): Dictionary<AccountInfo> {
      return this.$store.direct.state.accounts.all;
    },
    counts(): AccountCounts | null {
      return this.accounts[this.account.id]?.counts ?? null;
    },
    mostRecentActivity(): AccountActivity | null {
      return this.accounts[this.account.id]?.mostRecentActivity ?? null;
    },
    disabledInfo(): AccountEvents.DisabledInfo | false | null {
      return this.account.disabled ?? null;
    },
    accountIsDisabled(): boolean {
      return !!this.disabledInfo;
    },
    equipmentCount(): number {
      const active = this.counts?.activeEquipment || 0;
      const archived = this.counts?.archivedEquipment || 0;
      return active + archived;
    },
    locationCount(): number {
      const active = this.counts?.activeLocations || 0;
      const archived = this.counts?.archivedLocations || 0;
      return active + archived;
    }
  },
  mounted() {
    console.log("AccountListItem");
    if (this.account.id) {
      void this.$store.direct.dispatch.watchCountsForAccount(this.account.id);
      void this.$store.direct.dispatch.watchLastActivityForAccount(this.account.id);
    }
  }
});
