















import Vue from "vue";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";

import ListFirestore from "@/components/ListFirestore.vue";
import UserName from "@/components/UserName.vue";

export default Vue.extend({
  name: "GlobalAdmins",
  components: { ListFirestore, UserName },

  methods: {
    getGlobalAdminsCollection(): CollectionReference {
      return db.collection("global-admin").doc("administrators").collection("list");
    },
    orderList(items: Array<string>): Array<string> {
      return orderBy(Object.values(items));
    }
  }
});
