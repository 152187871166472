import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";

export const config = {
  apiKey: "AIzaSyA7zFh75VL87xMhNmfbxsiJOcUsHECGv8M",
  authDomain: "inventory-shield.firebaseapp.com",
  databaseURL: "https://inventory-shield.firebaseio.com",
  projectId: "inventory-shield",
  storageBucket: "inventory-shield.appspot.com",
  messagingSenderId: "49614934568",
  appId: "1:49614934568:web:a3f7007c275b6d24ae9b1d",
  measurementId: "G-RSTE2EB6Z3"
};

const initializedFirebase = firebase.initializeApp(config);

export const ACCOUNTS_SEARCH_INDEX = "accounts";

// Firestore
export const firestore = firebase.firestore();
export const db = firestore;
export const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
export const Timestamp = firebase.firestore.Timestamp;
export const GeoPoint = firebase.firestore.GeoPoint;
export const FieldValue = firebase.firestore.FieldValue;

// Auth
export const auth = firebase.auth;
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
auth().useDeviceLanguage();

// Storage
export const storage = firebase.storage();
export const TaskState = firebase.storage.TaskState;
export const TaskEvent = firebase.storage.TaskEvent;

// Functions
export const functions = firebase.functions;
export const callableFunctions = {
  disableAccount: firebase.functions().httpsCallable("disableAccount"),
  fixAccountCounts: firebase.functions().httpsCallable("fixAccountCounts"),
  getSizeOfBackup: firebase.functions().httpsCallable("getSizeOfBackup"),
  getGlobalAdminSearchAuthKey: firebase.functions().httpsCallable("getGlobalAdminSearchAuthKey"),
  registerAccount: firebase.functions().httpsCallable("registerAccount"),
  reenableAccount: firebase.functions().httpsCallable("reenableAccount"),
  runFirestoreBackup: firebase.functions().httpsCallable("runFirestoreBackup"),
  getPaymentEventsReport: firebase.functions().httpsCallable("getPaymentEventsReport"),
  // Equipment Pointee to QR Code Conversion
  convertEquipmentPointers: firebase.functions().httpsCallable("convertEquipmentPointers"),
  deleteEquipmentPointees: firebase.functions().httpsCallable("deleteEquipmentPointees"),
  getNumPointees: firebase.functions().httpsCallable("getNumPointees"),
  getPointeeIdsWithoutQRCodes: firebase.functions().httpsCallable("getPointeeIdsWithoutQRCodes")
};

if (process.env.VUE_APP_USE_FIREBASE_EMULATORS === "true") {
  firestore.settings({
    host: "localhost:5002",
    ssl: false
  });
  firebase.functions().useEmulator("localhost", 5001);
  firebase.auth().useEmulator("http://localhost:9099/");
}

export default initializedFirebase;
