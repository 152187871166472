import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { colorSchemes } from "../uiStateConstants";
import { isMobileDevice } from "../../helpers/device";

function getDefaultState() {
  return {
    authInfoHasLoaded: false,
    pageTitle: "Inventory Admin Portal",
    preferredColorScheme: colorSchemes.default,
    sidenavOpen: false,
    viewStates: {} as Dictionary<unknown>
  };
}

const UserInterfaceStateStore = defineModule({
  state: getDefaultState(),
  mutations: {
    SIDENAV_OPEN(state) {
      state.sidenavOpen = true;
    },
    SIDENAV_CLOSE(state) {
      state.sidenavOpen = false;
    },
    SIDENAV_TOGGLE(state) {
      state.sidenavOpen = !state.sidenavOpen;
    },
    UPDATE_PAGE_TITLE(state, newTitle: string) {
      state.pageTitle = newTitle;
    },
    AUTH_INFO_LOADED(state) {
      state.authInfoHasLoaded = true;
    },
    UPDATE_VIEW_STATE(state, { id, data }: { id: string; data?: unknown }) {
      console.log("UPDATE_VIEW_STATE", { id, data });
      if (data === undefined) {
        Vue.delete(state.viewStates, id);
      } else {
        Vue.set(state.viewStates, id, data);
      }
    },
    UPDATE_PREFERRED_COLOR_SCHEME(state, newColorScheme: "light" | "dark") {
      console.log("User wants color scheme:", newColorScheme);
      if (Object.values(colorSchemes).includes(newColorScheme)) {
        state.preferredColorScheme = newColorScheme;
      } else {
        state.preferredColorScheme = colorSchemes.default;
      }
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    openSidenav(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_OPEN();
    },
    closeSidenav(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_CLOSE();
    },
    toggleSidenav(context): void {
      const { commit } = actionContext(context);
      commit.SIDENAV_TOGGLE();
    },
    activateDarkMode(context): void {
      const { commit } = actionContext(context);
      commit.UPDATE_PREFERRED_COLOR_SCHEME(colorSchemes.dark);
    },
    activateLightMode(context): void {
      const { commit } = actionContext(context);
      commit.UPDATE_PREFERRED_COLOR_SCHEME(colorSchemes.light);
    },
    applyColorScheme(context): void {
      const { dispatch } = actionContext(context);
      const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches;
      const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
      const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;

      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addListener(e => e.matches && dispatch.activateDarkMode());
      window
        .matchMedia("(prefers-color-scheme: light)")
        .addListener(e => e.matches && dispatch.activateLightMode());

      if (isDarkMode) void dispatch.activateDarkMode();
      if (isLightMode) void dispatch.activateLightMode();
      if (isNotSpecified || hasNoSupport) {
        console.log("System color scheme not supported. Defaulting to light.");
        void dispatch.activateLightMode();
      }
    },
    async copyAndShareURL(context, url: string): Promise<void> {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url);
      }
      if (isMobileDevice && navigator.share) {
        await navigator.share({
          title: "Invitation Shield Invitation Code",
          url
        });
      }
    }
  },
  getters: {
    pageTitle: state => state.pageTitle
  }
});

export default UserInterfaceStateStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, UserInterfaceStateStore);
