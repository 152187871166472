







































import Vue from "vue";
import firebase from "@/firebase";
import router from "@/router";
import { isError } from "@/typings/guards";

import ActionButton from "@/components/ActionButton.vue";
import SetupPageWrapper from "@/components/SetupPageWrapper.vue";
import TextInput from "@/components/TextInput.vue";
import InventoryShieldVertical from "@/icons/InventoryShieldVertical.vue";

export default Vue.extend({
  name: "Login",
  components: {
    ActionButton,
    InventoryShieldVertical,
    SetupPageWrapper,
    TextInput
  },
  data: () => ({
    email: "",
    password: "",
    error: null as string | null,
    loggingIn: false
  }),
  methods: {
    async onSubmit(event: Event): Promise<void> {
      this.error = null;
      if (!this.loggingIn) {
        this.loggingIn = true;
        event.preventDefault();
        try {
          await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
          this.loggingIn = false;
          await router.push("/app");
        } catch (error: unknown) {
          console.error(error);
          if (isError(error)) {
            this.error = error.message;
          } else {
            this.error = JSON.stringify(error);
          }
          this.loggingIn = false;
        }
      }
    }
  }
});
