





















































import Vue, { PropType } from "vue";

import UserIcon from "@/icons/User.vue";
import SquareCameraView from "./SquareCameraView.vue";
import { Dictionary } from "vue-router/types/router";

export default Vue.extend({
  name: "ProfileImage",
  components: {
    SquareCameraView,
    UserIcon
  },
  props: {
    value: { type: Object as PropType<unknown>, default: null },
    editable: { type: Boolean, default: false },
    userId: { type: String as PropType<string | null>, default: null },
    showsFirstLetter: { type: Boolean, default: true },
    letterSize: { type: String, default: "0.95em" }
  },
  data() {
    return {
      editingImage: false,
      imageFailedToLoad: false
    };
  },
  computed: {
    allUsers(): Dictionary<User> {
      return this.$store.direct.state.users.items;
    },
    user(): User | null {
      if (!this.userId) return null;
      return this.allUsers[this.userId] ?? null;
    },
    letter(): string | null {
      if (this.user?.name && this.showsFirstLetter) {
        return this.user.name.charAt(0);
      } else {
        return null;
      }
    },
    editLabel(): string {
      if (this.user?.photoURL && !this.editingImage) {
        return "edit";
      } else if (this.editingImage) {
        return "cancel";
      } else if (this.user) {
        return "add photo";
      } else {
        return "";
      }
    }
  },
  watch: {
    editable(isEditable: boolean) {
      if (!isEditable) {
        this.editingImage = false;
      }
    }
  },
  methods: {
    async editImageIfPossible(event: Event): Promise<void> {
      if (this.editable) {
        this.editingImage = !this.editingImage;

        if (this.editingImage) {
          this.$emit("begin-editing", event);
          await this.$nextTick();
          const cameraView = this.$refs.cameraView as { start: () => Promise<void> } | undefined;
          await cameraView?.start();
        } else {
          this.$emit("end-editing", event);
        }
      }
    },
    newImageTaken(image: unknown): void {
      this.$emit("input", image);
    }
  }
});
