


















import Vue from "vue";

import BackArrowIcon from "@/icons/BackArrow.vue";
import Logo from "@/icons/InventoryShield.vue";
import MenuIcon from "@/icons/Menu.vue";

export default Vue.extend({
  name: "Navbar",
  components: {
    Logo,
    BackArrowIcon,
    MenuIcon
  },
  props: {
    title: { type: String, default: "" },
    showBackButton: { type: Boolean, default: true },
    showSidebar: { type: Boolean, default: true }
  },
  data: () => ({
    backButtonLocation: "/app/"
  }),
  methods: {
    goBack(): void {
      this.$router.go(-1);
      void this.$store.direct.dispatch.closeSidenav();
    },
    toggleSidenav(): void {
      // console.log("toggle sidenav");
      void this.$store.direct.dispatch.toggleSidenav();
    },
    closeSidenav(): void {
      void this.$store.direct.dispatch.closeSidenav();
    }
  }
});
