


















import Vue, { PropType } from "vue";
import {
  LOCATION_TYPE_ICON_CHURCH,
  LOCATION_TYPE_ICON_FIRE_TRUCK,
  LOCATION_TYPE_ICON_FIRE_STATION,
  LOCATION_TYPE_ICON_HOME,
  LOCATION_TYPE_ICON_HOSPITAL,
  LOCATION_TYPE_ICON_LOCATION,
  LOCATION_TYPE_ICON_OFFICE,
  LOCATION_TYPE_ICON_ROOM,
  LOCATION_TYPE_ICON_SCHOOL,
  LOCATION_TYPE_ICON_SHOP,
  LOCATION_TYPE_ICON_TRUCK,
  LOCATION_TYPE_ICON_USER
} from "@/locationTypeIcons";

import ChurchIcon from "@/icons/Church.vue";
import FireTruckIcon from "@/icons/FireTruck.vue";
import FireStationIcon from "@/icons/FireStation.vue";
import HomeIcon from "@/icons/Home.vue";
import HospitalIcon from "@/icons/Hospital.vue";
import MapLocationIcon from "@/icons/ActiveJobSite.vue";
import OfficeIcon from "@/icons/Office.vue";
import QuestionMarkIcon from "@/icons/QuestionMark.vue";
import RoomIcon from "@/icons/Room.vue";
import SchoolIcon from "@/icons/School.vue";
import ShopIcon from "@/icons/Shop.vue";
import TruckIcon from "@/icons/Truck.vue";
import UserIcon from "@/icons/User.vue";

export default Vue.extend({
  name: "LocationTypeIcon",
  components: {
    ChurchIcon,
    FireTruckIcon,
    FireStationIcon,
    HomeIcon,
    HospitalIcon,
    MapLocationIcon,
    RoomIcon,
    OfficeIcon,
    QuestionMarkIcon,
    SchoolIcon,
    ShopIcon,
    TruckIcon,
    UserIcon
  },
  props: {
    type: { type: String as PropType<string | null>, default: null },
    icon: { type: String, required: true }
  },
  data: () => ({
    firestoreHandle: false,
    observer: null as IntersectionObserver | null,
    isIntersecting: false,
    error: null as Error | null,
    status: null as unknown | null
  }),
  computed: {
    LOCATION_TYPE_ICON_CHURCH: () => LOCATION_TYPE_ICON_CHURCH,
    LOCATION_TYPE_ICON_FIRE_TRUCK: () => LOCATION_TYPE_ICON_FIRE_TRUCK,
    LOCATION_TYPE_ICON_FIRE_STATION: () => LOCATION_TYPE_ICON_FIRE_STATION,
    LOCATION_TYPE_ICON_HOME: () => LOCATION_TYPE_ICON_HOME,
    LOCATION_TYPE_ICON_HOSPITAL: () => LOCATION_TYPE_ICON_HOSPITAL,
    LOCATION_TYPE_ICON_LOCATION: () => LOCATION_TYPE_ICON_LOCATION,
    LOCATION_TYPE_ICON_OFFICE: () => LOCATION_TYPE_ICON_OFFICE,
    LOCATION_TYPE_ICON_ROOM: () => LOCATION_TYPE_ICON_ROOM,
    LOCATION_TYPE_ICON_SCHOOL: () => LOCATION_TYPE_ICON_SCHOOL,
    LOCATION_TYPE_ICON_SHOP: () => LOCATION_TYPE_ICON_SHOP,
    LOCATION_TYPE_ICON_TRUCK: () => LOCATION_TYPE_ICON_TRUCK,
    LOCATION_TYPE_ICON_USER: () => LOCATION_TYPE_ICON_USER,
    displayIcon(): string {
      return this.icon;
    }
  }
});
