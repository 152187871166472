






import Vue from "vue";

export default Vue.extend({
  name: "ErrorNotice",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    error: { required: true },
    heading: { type: String, default: null }
  }
});
