











































import Vue, { PropType } from "vue";
import ActionButton from "./ActionButton.vue";
import Checkbox from "./Checkbox.vue";
import NumberInput from "./NumberInput.vue";
import ErrorNotice from "./ErrorNotice.vue";

export default Vue.extend({
  name: "AccountLimitsForm",
  components: {
    ActionButton,
    Checkbox,
    ErrorNotice,
    NumberInput
  },
  props: {
    type: { type: String, required: true },
    formValues: { type: Object as PropType<Partial<AccountLimits> | null>, default: null },
    onSubmit: {
      type: Function as PropType<(arg0: AccountLimits) => Promise<void>>,
      required: true
    }
  },
  data() {
    return {
      unlimitedAdministrators: this.formValues?.administrators === null,
      administrators: this.formValues?.administrators ?? 0,

      unlimitedEquipment: this.formValues?.equipment === null,
      equipment: this.formValues?.equipment ?? 0,

      unlimitedSuperusers: this.formValues?.superusers === null,
      superusers: this.formValues?.superusers ?? 0,

      unlimitedTechnicians: this.formValues?.technicians === null,
      technicians: this.formValues?.technicians ?? 0,

      submitting: false,
      error: null as Error | null
    };
  },
  computed: {
    hasChanges(): boolean {
      const admins = this.unlimitedAdministrators ? null : this.administrators;
      const equipment = this.unlimitedEquipment ? null : this.equipment;
      const superusers = this.unlimitedSuperusers ? null : this.superusers;
      const technicians = this.unlimitedTechnicians ? null : this.technicians;
      return (
        admins !== this.formValues?.administrators ||
        equipment !== this.formValues.equipment ||
        superusers !== this.formValues.superusers ||
        technicians !== this.formValues.technicians
      );
    }
  },
  watch: {
    administrators() {
      this.error = null;
    },
    equipment() {
      this.error = null;
    },
    superusers() {
      this.error = null;
    },
    technicians() {
      this.error = null;
    }
  },
  methods: {
    async submitAccountLimitsForm(): Promise<void> {
      if (this.submitting === false) {
        this.submitting = true;
        this.error = null;
        try {
          const administrators = this.unlimitedAdministrators ? null : this.administrators;
          const equipment = this.unlimitedEquipment ? null : this.equipment;
          const superusers = this.unlimitedSuperusers ? null : this.superusers;
          const technicians = this.unlimitedTechnicians ? null : this.technicians;
          await this.onSubmit({ administrators, equipment, superusers, technicians });
        } catch (error) {
          console.error(error);
          this.error = error as Error;
        }
        this.submitting = false;
      }
    }
  }
});
