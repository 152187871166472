






import Vue from "vue";

export default Vue.extend({
  name: "AccountName",
  props: {
    accountId: { type: String, required: true },
    normal: { type: Boolean, default: false }
  },
  computed: {
    account(): AccountInfo | null {
      const id = this.accountId;
      if (id) {
        return this.$store.direct.state.accounts.all[id] ?? null;
      }
      return null;
    },
    name(): string | null {
      return this.account?.name ?? null;
    }
  }
});
