




























import Vue from "vue";

export default Vue.extend({
  name: "TextAreaInput",
  props: {
    value: { type: String, default: "" },
    type: { type: String, default: "text" },
    maxlength: { type: String, default: "" },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: "" }
  },
  data() {
    return {
      inputValue: this.value,
      maxlengthValue: this.maxlength,
      isFocused: false
    };
  },
  watch: {
    value(newValue: string) {
      this.inputValue = newValue;
    }
  },
  methods: {
    input(event: InputEvent): void {
      const target = event.target as HTMLTextAreaElement | null;
      this.$emit("input", target?.value);
    },
    focus(): void {
      const target = this.$refs.input as HTMLTextAreaElement | undefined;
      target?.focus();
    },
    onFocus(event: FocusEvent): void {
      this.isFocused = true;
      this.$emit("focus", event);
    },
    onBlur(event: Event): void {
      this.isFocused = false;
      this.$emit("blur", event);
    }
  }
});
