/**
 * Returns a copy of the given `originalObject`, including only keys whose value is not `undefined`.
 */
export default function withoutUndefinedProperties<T>(
  originalObject: Record<string, T | undefined>
): Record<string, T> {
  return Object.entries(originalObject).reduce((newObject: Record<string, T>, [key, value]) => {
    if (value === undefined) {
      return newObject;
    }
    newObject[key] = value;
    return newObject;
  }, {});
}
