export const LOCATION_TYPE_ICON_CHURCH = "church";
export const LOCATION_TYPE_ICON_LOCATION = "map-location";
export const LOCATION_TYPE_ICON_FIRE_TRUCK = "fire-truck";
export const LOCATION_TYPE_ICON_FIRE_STATION = "fire-station";
export const LOCATION_TYPE_ICON_HOME = "home";
export const LOCATION_TYPE_ICON_HOSPITAL = "hospital";
export const LOCATION_TYPE_ICON_OFFICE = "office";
export const LOCATION_TYPE_ICON_ROOM = "room";
export const LOCATION_TYPE_ICON_SCHOOL = "school";
export const LOCATION_TYPE_ICON_SHOP = "shop";
export const LOCATION_TYPE_ICON_TRUCK = "truck";
export const LOCATION_TYPE_ICON_USER = "user";
