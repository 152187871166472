













import Vue, { PropType } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import LocationTypeEditModal from "./LocationTypeEditModal.vue";
import LocationType from "@/components/LocationType.vue";

export default Vue.extend({
  name: "LocationTypeConfigItem",
  components: {
    ActionButton,
    LocationTypeEditModal,
    LocationType
  },
  props: {
    locationType: { type: Object as PropType<LocationType>, required: true },
    accountId: { type: String, required: true }
  },
  data: () => ({
    isEditing: false
  })
});
