




































import Vue from "vue";

import NavigatingButton from "@/components/NavigatingButton.vue";
import PublicStats from "@/components/PublicStats.vue";

import AccountsIcon from "@/icons/Accounts.vue";
import BackupsIcon from "@/icons/Backups.vue";
import GithashIcon from "@/icons/GithashIcon.vue";
import GlobalAdmins from "@/icons/GlobalAdmins.vue";
import ReportsIcon from "@/icons/Reports.vue";
import SignupsIcon from "@/icons/Signups.vue";

export default Vue.extend({
  name: "Home",
  components: {
    AccountsIcon,
    BackupsIcon,
    GlobalAdmins,
    GithashIcon,
    NavigatingButton,
    PublicStats,
    ReportsIcon,
    SignupsIcon
  },
  computed: {
    backupCount(): number | null {
      return this.$store.direct.state.backups.count;
    },
    numberOfAccounts(): number {
      return Object.values(this.$store.direct.state.accounts.all).length;
    }
  }
});
