























































































import Vue, { PropType } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import InventoryShieldProduct from "@/components/InventoryShieldProduct.vue";
import UserName from "@/components/UserName.vue";
import UserEmail from "@/components/UserEmail.vue";

export default Vue.extend({
  name: "SignupListItem",
  components: {
    ActionButton,
    UserName,
    UserEmail,
    InventoryShieldProduct
  },
  props: {
    signup: { type: Object as PropType<Signup>, required: true }
  },
  computed: {
    hasAccountDetails(): boolean {
      return !!this.signup?.account && Object.keys(this.signup?.account ?? {}).length > 0;
    },
    hasLabelPreferences(): boolean {
      return !!this.signup?.labels;
    },
    hasStartedCheckingOut(): boolean {
      console.log("hasStartedCheckingOut", this.signup?.product, this.signup?.coupon);
      return !!this.signup?.product || !!this.signup?.coupon;
    }
  },
  methods: {
    getFileName(fileUrl: string): string {
      return decodeURIComponent(fileUrl.split("%2F")[2]?.split("?")[0] ?? "");
    },
    getAddressURL(address: string): string {
      const encodedAddress = encodeURIComponent(address);
      return `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    }
  }
});
