import Vue from "vue";
import Vuex from "vuex";
import { createDirectStore, GettersImpl } from "direct-vuex";

import {
  AccountStore,
  BackupStore,
  CurrentUserStore,
  EquipmentStore,
  ProductStore,
  UserStore,
  UserInterfaceStore
} from "./modules";

Vue.use(Vuex);

export type RootGetters = typeof AccountStore.getters &
  // typeof BackupStore.getters &
  typeof CurrentUserStore.getters &
  // typeof EquipmentStore.getters &
  // typeof ProductStore.getters &
  // typeof UserStore.getters &
  typeof UserInterfaceStore.getters;

export type RootState = {
  accounts: typeof AccountStore.state;
  backups: typeof BackupStore.state;
  currentUser: typeof CurrentUserStore.state;
  equipment: typeof EquipmentStore.state;
  products: typeof ProductStore.state;
  ui: typeof UserInterfaceStore.state;
  users: typeof UserStore.state;
};

// eslint-disable-next-line @typescript-eslint/unbound-method
const { store, moduleActionContext, moduleGetterContext } = createDirectStore({
  modules: {
    accounts: AccountStore,
    backups: BackupStore,
    currentUser: CurrentUserStore,
    equipment: EquipmentStore,
    products: ProductStore,
    ui: UserInterfaceStore,
    users: UserStore
  },
  getters: {} as RootGetters & GettersImpl<RootState, RootGetters>
});

export default store;
export type State = typeof store.state;
export type Getters = typeof store.getters;

export { moduleActionContext, moduleGetterContext };

export type InventoryShieldAdminPortalStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    direct: InventoryShieldAdminPortalStore;
  }
}
