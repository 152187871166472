













import ListFirestore from "./ListFirestore.vue";
import LocationTypeConfigItem from "./LocationTypeConfigItem.vue";
import orderBy from "lodash/orderBy";
import Vue from "vue";

import { db } from "@/firebase";

export default Vue.extend({
  name: "LocationTypeList",
  components: {
    ListFirestore,
    LocationTypeConfigItem
  },
  props: {
    accountId: { type: String, required: true }
  },
  methods: {
    getLocationTypesCollection(): CollectionReference {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("info")
        .doc("settings")
        .collection("locationTypes");
    },
    orderList(items: Array<string>): Array<string> {
      return orderBy(Object.values(items), "active", "desc");
    }
  }
});
