




import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Observer",
  props: {
    options: {
      type: Object as PropType<IntersectionObserverInit | null>,
      default: null
    }
  },
  data: () => ({
    observer: null as IntersectionObserver | null
  }),
  mounted() {
    const options = this.options ?? {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);
    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer?.disconnect();
  }
});
