import isString from "lodash/isString";
import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import isError from "lodash/isError";
import { Timestamp } from "../firebase";

export { isNumber, isString, isError, isArray };

export function isStringArray(toBeDetermined: unknown): toBeDetermined is Array<string> {
  return !!toBeDetermined && isArray(toBeDetermined) && toBeDetermined.every(isString);
}

export function isNumberArray(toBeDetermined: unknown): toBeDetermined is Array<number> {
  return !!toBeDetermined && isArray(toBeDetermined) && toBeDetermined.every(isNumber);
}

export function isRecord(toBeDetermined: unknown): toBeDetermined is Record<string, unknown> {
  return !!toBeDetermined && typeof toBeDetermined === "object" && !Array.isArray(toBeDetermined);
}

export function isNonEmptyArray<T>(array: Array<T>): array is NonEmptyArray<T> {
  return array.length > 0;
}

export function isDeviceInfo(toBeDetermined: unknown): toBeDetermined is DeviceInfo {
  if (
    isRecord(toBeDetermined) &&
    (typeof (toBeDetermined as BrowserInfo).browser === "string" ||
      typeof (toBeDetermined as PhysicalDeviceInfo).cordova === "string")
  ) {
    return true;
  }
  return false;
}

export function isBuildInfo(toBeDetermined: unknown): toBeDetermined is BuildInfo {
  if (isRecord(toBeDetermined)) {
    // FIXME: Not much here, since it's such a thin type
    return true;
  }
  return false;
}

export function isValidMessage(toBeDetermined: unknown): toBeDetermined is AppMessage {
  if (
    isRecord(toBeDetermined) &&
    (toBeDetermined as AppMessage).type &&
    typeof (toBeDetermined as AppMessage).type === "string"
  ) {
    return true;
  }
  return false;
}

// export function isServerAttachment(
//   toBeDetermined: AttachmentLike
// ): toBeDetermined is ServerAttachment {
//   if (
//     isRecord(toBeDetermined) &&
//     typeof (toBeDetermined as ServerAttachment).url === "string" &&
//     (toBeDetermined as ServerAttachment).url !== undefined
//   ) {
//     return true;
//   }
//   return false;
// }

/**
 * Guards that the given value is a truthy object with the given key.
 *
 * @param toBeDetermined The value to check.
 * @param key The key that the value should have.
 */
export function isObjectWithKey<P extends PropertyKey>(
  toBeDetermined: unknown,
  key: P
): toBeDetermined is { [K in P]: unknown } {
  return isRecord(toBeDetermined) && key in toBeDetermined;
}

/**
 * Guards that the given value is a truthy object with the given keys.
 *
 * @param toBeDetermined The value to check.
 * @param keys The keys that the value should have.
 */
export function isObjectWithKeys<P extends PropertyKey>(
  toBeDetermined: unknown,
  keys: Array<P>
): toBeDetermined is { [K in P]: unknown } {
  return isRecord(toBeDetermined) && keys.every(key => key in toBeDetermined);
}

/**
 * An error to throw in the `default` case of a `switch` statement to enable strict
 * exhaustiveness checking.
 *
 * Not compatible with `undefined` cases.
 *
 * For example:
 * ```
 * function getAnswer(taste: IceCreamTaste) {
 *   switch (taste) {
 *     case IceCreamTaste.awesome:
 *       return `It's like a party in my mouth!`;
 *     case IceCreamTaste.meh:
 *       return 'Umm I think someone has eaten this before me.';
 *     case IceCreamTaste.dunnoYet:
 *       return 'Lemme try it first, ok?';
 *     default:
 *       throw new UnreachableCaseError(taste);
 *   }
 * }
 * ```
 *
 * @see http://ideasintosoftware.com/exhaustive-switch-in-typescript/
 */
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`);
  }
}

/**
 * Returns the provided value if it is nonnull and non-undefined and a number. Otherwise, returns zero.
 */
export function numberOrZero(value: unknown): number {
  if (value !== null && value !== undefined && isNumber(value)) {
    return value;
  }
  return 0;
}

/**
 * Returns the provided value if it is nonnull and non-undefined and a number. Otherwise, returns `null`.
 */
export function numberOrNull(value: unknown): number | null {
  if (value !== null && value !== undefined && isNumber(value)) {
    return value;
  }
  return null;
}

/**
 * Returns the provided value if it is nonnull and non-undefined and a number. Otherwise, returns `undefined`.
 */
export function numberOrUndefined(value: unknown): number | undefined {
  if (value !== null && value !== undefined && isNumber(value)) {
    return value;
  }
  return undefined;
}

/**
 * Returns the provided value if it is non`null` and non-`undefined` and a string. Otherwise, returns `undefined`.
 */
export function stringOrUndefined(value: unknown): string | undefined {
  if (value !== undefined && value !== null && isString(value)) {
    return value;
  }
  return undefined;
}

/**
 * Returns the provided value if it is non`null` and non-`undefined` and a string. Otherwise, returns `null`.
 */
export function stringOrNull(value: unknown): string | null {
  if (value !== undefined && value !== null && isString(value)) {
    return value;
  }
  return null;
}

/**
 * Returns the provided value if it is `null` or `undefined` or a Firestore `Timestamp`. Otherwise, returns `undefined`.
 */
export function timestamp(value: unknown): Timestamp | null | undefined {
  if (value === null || value === undefined) {
    return value;
  }
  if (value instanceof Timestamp) {
    return value;
  }
  return undefined;
}
