import Vue from "vue";
import PortalVue from "portal-vue";
import Toasted from "vue-toasted";
import * as VueGoogleMaps from "@inventory-shield/vue2-google-maps";
import { GOOGLE_MAPS_API_KEY } from "./constants";

interface VueGoogleMaps {
  loadGmapApi(options: { key: string; libraries: string }): void;
}

Vue.use(PortalVue);
Vue.use(Toasted, { containerClass: "cleanup-toast" });
Vue.use(VueGoogleMaps);
(VueGoogleMaps as VueGoogleMaps).loadGmapApi({
  key: GOOGLE_MAPS_API_KEY,
  libraries: "places,geocoder,geometry"
});
