
















import Vue from "vue";
import orderBy from "lodash/orderBy";
import { Subject } from "rxjs";
import { db } from "@/firebase";

import SignupListItem from "@/components/SignupListItem.vue";
import ListFirestore from "@/components/ListFirestore.vue";

interface SortOptions {
  createdAt: string;
  name: string;
  mostRecentScanTime: string;
}

export default Vue.extend({
  name: "Signups",
  components: {
    ListFirestore,
    SignupListItem
  },
  data: () => ({
    createAccountModalIsOpen: false,
    selectedSortOption: "createdAt" as keyof SortOptions,
    onReset: new Subject()
  }),
  computed: {
    sortOptions() {
      return { mostRecentScanTime: "Last Scan", createdAt: "Date Created", name: "Name" };
    },
    sortDirection(): "asc" | "desc" {
      switch (this.selectedSortOption) {
        case "createdAt":
        case "mostRecentScanTime":
          return "desc";
        case "name":
        default:
          return "asc";
      }
    }
  },
  watch: {
    selectedSortOption() {
      this.onReset.next();
    }
  },
  methods: {
    getSignupsCollection(): FirestoreQuery {
      return db.collection("signups").orderBy("startedAt", "desc");
    },
    orderList(items: Array<unknown>): Array<unknown> {
      return orderBy(Object.values(items), this.selectedSortOption, this.sortDirection);
    }
  }
});
