

























import Vue from "vue";
import type { CurrentUserState } from "@/store/modules/CurrentUserStore";

import ProfileImage from "./ProfileImage.vue";

export default Vue.extend({
  name: "Sidenav",
  components: {
    ProfileImage
  },
  computed: {
    currentUser(): CurrentUserState {
      return this.$store.direct.state.currentUser;
    },
    open(): boolean {
      return this.$store.direct.state.ui.sidenavOpen;
    }
  },
  methods: {
    closeSideNav(): void {
      void this.$store.direct.dispatch.toggleSidenav();
    }
  }
});
