



























import Vue from "vue";
import { GOOGLE_MAPS_API_KEY } from "@/constants";
import { isError } from "@/typings/guards";

import ErrorNotice from "@/components/ErrorNotice.vue";

interface GmapsAPILocation {
  lat?: number | null;
  lng?: number | null;
}

interface GmapsAPIGeometry {
  location: GmapsAPILocation;
}

interface GmapsAPIResult {
  formatted_address?: string;
  geometry?: GmapsAPIGeometry;
  place_id?: string;
}

interface GmapsAPIResponse {
  results?: Array<GmapsAPIResult>;
}

export default Vue.extend({
  name: "AddressInput",
  components: {
    ErrorNotice
  },
  props: {
    value: { type: String, default: "" },
    type: { type: String, default: "text" },
    label: { type: String, default: "" },
    autofill: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  },
  data() {
    return {
      isFocused: false,
      address: this.value,
      loadingAddress: false,
      error: null as string | null,
      autocomplete: null as google.maps.places.Autocomplete | null
    };
  },
  mounted() {
    const addressInput = this.$refs.addressInput as HTMLInputElement | undefined;
    if (window.google && addressInput) {
      this.autocomplete = new window.google.maps.places.Autocomplete(addressInput, {
        types: [`geocode`],
        componentRestrictions: { country: [`us`] }
      });
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete?.getPlace();
        if (place) {
          let coordinates: GmapsAPILocation | null = null;
          const { formatted_address, geometry, place_id } = place;
          if (place_id) {
            this.address = formatted_address ?? "";
            coordinates = {
              lat: geometry?.location.lat() ?? null,
              lng: geometry?.location.lng() ?? null
            };
          }
          this.$emit("input", formatted_address, coordinates, place_id);
          this.$emit("blur");
        }
      });
      if (this.autofill) {
        void this.getLocation();
      }
    }
  },
  methods: {
    async getLocation(): Promise<void> {
      this.loadingAddress = true;
      this.error = null;
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_API_KEY}`
        );
        const jsonResponse = (await response.json()) as GmapsAPIResponse;
        console.log("getLocation", jsonResponse);
        const results = jsonResponse.results ?? [];
        if (results.length > 0) {
          const place: GmapsAPIResult = results[0] ?? {};
          const { formatted_address, geometry, place_id } = place;
          if (place_id) {
            this.address = formatted_address ?? "";
            const coordinates: GmapsAPILocation = geometry?.location ?? {};
            this.$emit("input", formatted_address, coordinates, place_id);
          }
        } else {
          throw new Error("No results");
        }
      } catch (error: unknown) {
        this.loadingAddress = false;
        if (isError(error)) {
          this.error = error.message;
        } else {
          this.error = JSON.stringify(error);
        }
        console.error(error);
        setTimeout(() => {
          this.error = null;
        }, 15000);
      }
      this.loadingAddress = false;
    },
    onInput(): void {
      this.$emit("input", this.address);
    },
    onFocus(event: Event): void {
      this.isFocused = true;
      this.$emit("focus", event);
    },
    onBlur(event: Event): void {
      this.isFocused = false;
      this.$emit("blur", event);
    },
    focus(): void {
      const addressInput = this.$refs.addressInput as HTMLInputElement | undefined;
      addressInput?.focus();
    }
  }
});
