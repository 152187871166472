























import Vue from "vue";
import { config, db } from "@/firebase";
import { isError } from "@/typings/guards";

import ActionButton from "./ActionButton.vue";
import ErrorNotice from "./ErrorNotice.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import QRCode from "./QRCode.vue";

import LinkIcon from "@/icons/Link.vue";

const { apiKey } = config;

export default Vue.extend({
  name: "GetInvitationCodeLink",
  components: {
    ActionButton,
    ErrorNotice,
    LinkIcon,
    LoadingIndicator,
    QRCode
  },
  props: {
    code: { type: String, required: true },
    url: { type: String, default: null }
  },
  data: () => ({
    sharing: false,
    shareSuccess: false,
    error: null as Error | string | null
  }),
  computed: {
    isLoading(): boolean {
      return this.url === null && this.error === null;
    }
  },
  mounted() {
    if (!this.url) {
      void this.generateInvitationCodeLink();
    }
  },
  methods: {
    async createShortLink(longDynamicLink: string): Promise<string> {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            longDynamicLink,
            suffix: {
              option: "UNGUESSABLE"
            }
          })
        }
      );
      const body = (await response.json()) as { shortLink: string };
      console.log("createShortLinkResponse", body);
      return body.shortLink;
    },
    async generateInvitationCodeLink(): Promise<void> {
      try {
        console.log("generateInvitationCodeLink", this.code);
        const bundleId = window.APP_INFO?.build?.basePackageName ?? "com.inventoryshield.app";
        const appStoreId = "1502458904";
        const rawParams = { invitationCode: this.code };
        const params = new URLSearchParams(rawParams).toString();
        const encodedLink = encodeURIComponent(`https://app.inventoryshield.com/signup?${params}`);
        // const encodedLink = encodeURIComponent(`http://localhost:8080/signup?${params}`);

        // https://firebase.google.com/docs/dynamic-links/create-manually
        const longUrl = `https://inventoryshield.page.link/?link=${encodedLink}&apn=${bundleId}&ibi=${bundleId}&isi=${appStoreId}`;
        const url = await this.createShortLink(longUrl);
        await db.collection("invitationCodes").doc(this.code).update({ url });
        console.log("generateInvitationCodeLink", url);
      } catch (error: unknown) {
        console.error(error);
        if (isError(error)) {
          this.error = error;
        } else {
          this.error = JSON.stringify(error);
        }
      }
    },
    async shareInvitationCodeLink(): Promise<void> {
      if (!this.url) {
        return;
      }
      console.log("shareInvitationCodeLink", this.url);
      if (this.sharing !== true) {
        this.sharing = true;
        this.shareSuccess = false;
        this.error = null;
        try {
          await this.$store.direct.dispatch.copyAndShareURL(this.url);
          this.shareSuccess = true;
        } catch (error: unknown) {
          console.error("Code share failed:", error);
          if (isError(error)) {
            this.error = error;
          } else {
            this.error = JSON.stringify(error);
          }
        }
        this.sharing = false;
      }
    }
  }
});
