















































import Vue, { PropType } from "vue";
import { toPermissionLevel } from "@/filters";

import InvitationCodeEditModal from "./InvitationCodeEditModal.vue";
import UserName from "./UserName.vue";

export default Vue.extend({
  name: "InvitationCodeListItem",
  components: {
    InvitationCodeEditModal,
    UserName
  },
  props: {
    invitationCode: { type: Object as PropType<InvitationCode>, required: true }
  },
  data: () => ({
    editModalIsOpen: false
  }),
  computed: {
    invitationCodeThatIsBeingEdited(): string | null {
      console.log("invitationCodeThatIsBeingEdited", this.$store.direct.state.ui.viewStates);
      return (
        (this.$store.direct.state.ui.viewStates?.invitationCodeThatIsBeingEdited as
          | string
          | undefined) ?? null
      );
    },
    toPermissionLevel() {
      return toPermissionLevel;
    }
  },
  watch: {
    editModalIsOpen(open: boolean): void {
      if (open) {
        return this.$store.direct.commit.UPDATE_VIEW_STATE({
          id: "invitationCodeThatIsBeingEdited",
          data: this.invitationCode.id
        });
      } else {
        return this.$store.direct.commit.UPDATE_VIEW_STATE({
          id: "invitationCodeThatIsBeingEdited"
        });
      }
    }
  },
  mounted() {
    console.log(
      "InvitationCodeListItem",
      this.invitationCodeThatIsBeingEdited,
      this.invitationCode.id
    );
    if (this.invitationCodeThatIsBeingEdited === this.invitationCode.id) {
      this.openEditModal();
    }
  },
  methods: {
    openEditModal(): void {
      this.editModalIsOpen = true;
    },
    closeEditModal(): void {
      this.editModalIsOpen = false;
    }
  }
});
