import Vue from "vue";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "../index";
import { firestore } from "../../firebase";

let backupsWatcher: Watcher | null = null;
function stopWatchingBackups() {
  if (backupsWatcher) {
    backupsWatcher();
    backupsWatcher = null;
  }
}

const getDefaultState = () => ({
  count: null as number | null
});

const BackupsStore = defineModule({
  state: getDefaultState(),
  mutations: {
    UPDATE_BACKUP_COUNT(state, count: number) {
      Vue.set(state, "count", count);
    },
    LOGOUT(state) {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    watchBackups(context): void {
      const { rootGetters, commit } = actionContext(context);
      console.log("watchBackups", rootGetters.currentUserHasGlobalAccess);
      stopWatchingBackups();
      if (!rootGetters.currentUserHasGlobalAccess) {
        return;
      }
      backupsWatcher = firestore
        .collection("global-admin")
        .doc("backups")
        .onSnapshot(backups => {
          console.log("watchBackups", backups);
          if (!backups.exists) {
            return;
          }
          const data = backups.data() as { count?: number } | undefined;
          commit.UPDATE_BACKUP_COUNT(data?.count ?? 0);
        }, console.error);
    },
    stopWatchingBackups(): void {
      stopWatchingBackups();
    }
  }
});

export default BackupsStore;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionContext = (context: any) => moduleActionContext(context, BackupsStore);
