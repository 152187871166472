






















import type { Item as ListItem } from "./List.vue";
import Vue, { PropType, PropOptions } from "vue";
import { Subject, Observable, Subscription } from "rxjs";

import List from "./List.vue";

export interface Item extends ListItem {
  /** The path of the Firestore reference described by this item. */
  _path: string;
}

export default Vue.extend({
  name: "ListFirestore",
  components: {
    List
  },
  props: {
    itemName: { type: String, default: "Item" },
    itemNamePlural: { type: String as PropType<string | null>, default: null },
    getCollection: {
      type: Function as PropType<() => FirestoreQuery | Promise<FirestoreQuery>>,
      required: true
    },
    onAddedOrUpdatedItems: {
      type: Function as PropType<(items: Dictionary<Item>) => void>,
      default: () => undefined
    } as PropOptions<(items: Dictionary<Item>) => void>,
    orderList: {
      type: Function as PropType<(items: Dictionary<Item>) => Item[]>,
      required: true
    },
    filterList: {
      type: Function as PropType<(items: Item[]) => Item[]>,
      default: (a: Item[]) => a
    } as PropOptions<(items: Item[]) => Item[]>,
    omitIds: { type: Array as PropType<string[]>, default: () => [] },
    limit: { type: Number as PropType<number | null>, default: 20 },
    showsEndNote: { type: Boolean, default: true },
    onReset: {
      type: Object as PropType<Observable<unknown>>,
      validator: value => value instanceof Observable,
      default: () => new Subject()
    },
    autoload: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false as boolean,
    error: null as Error | null,
    atEnd: false as boolean,
    items: {} as Dictionary<Item>,
    listWatchers: [] as {
      unsubscribe: Watcher;
      lastItemReceived: QueryDocumentSnapshot | null;
    }[],
    lastItemReceived: null as QueryDocumentSnapshot | null,
    resetSubscription: null as Subscription | null
  }),
  computed: {
    itemList(): Item[] {
      let orderedList = this.filterList(this.orderList(this.items));
      if (this.omitIds && this.omitIds.length > 0) {
        orderedList = orderedList.filter(item => !this.omitIds.includes(item.id));
      }
      return orderedList;
    }
  },
  watch: {
    autoload: {
      immediate: true,
      handler(autoload: boolean) {
        if (autoload) {
          void this.fetchMoreItems();
        }
      }
    },
    loading(loading: boolean) {
      if (!loading && this.autoload && !this.atEnd) {
        void this.fetchMoreItems();
      }
    },
    atEnd(atEnd: boolean) {
      if (atEnd) {
        this.$emit("loaded", this.$options.name);
      }
    }
  },
  created() {
    this.resetSubscription = this.onReset.subscribe(() => this.reset());
  },
  mounted() {
    void this.fetchMoreItems();
  },
  beforeDestroy(): void {
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
      this.resetSubscription = null;
    }
    this.listWatchers.map(({ unsubscribe }) => unsubscribe());
  },
  methods: {
    reset(): void {
      // console.log("reset", this.listWatchers);
      this.items = {};
      this.error = null;
      this.atEnd = false;
      this.listWatchers.forEach(({ unsubscribe }) => unsubscribe());
      this.listWatchers = [];
      this.lastItemReceived = null;
      void this.fetchMoreItems();
    },
    async fetchMoreItems(): Promise<void> {
      if (!this.loading && !this.atEnd) {
        this.loading = true;
        this.error = null;
        if (this.listWatchers.some(watcher => watcher.lastItemReceived === this.lastItemReceived)) {
          this.loading = false;
          return;
        }
        let collection = (await this.getCollection()).limit(this.limit ?? 20);
        if (this.lastItemReceived !== null) {
          collection = collection.startAfter(this.lastItemReceived);
        }
        const unsubscribe = collection.onSnapshot(
          list => {
            this.atEnd = list.size === 0 || list.size < (this.limit ?? 20);
            const itemsToAdd: Dictionary<Item> = {};
            list.docChanges().forEach(change => {
              const itemData = change.doc;
              this.lastItemReceived = itemData;
              if (change.type === "removed") {
                Vue.delete(this.items, itemData.id);
              } else {
                itemsToAdd[itemData.id] = {
                  id: itemData.id,
                  ...itemData.data(),
                  _path: itemData.ref.path
                };
              }
            });
            if (Object.keys(itemsToAdd).length > 0) {
              this.items = { ...this.items, ...itemsToAdd };
              this.onAddedOrUpdatedItems(itemsToAdd);
            }
            this.loading = false;
          },
          error => {
            console.error(`Could not render ${this.itemName} list:`, error);
            this.error = error;
            this.loading = false;
          }
        );
        this.listWatchers.push({ unsubscribe, lastItemReceived: this.lastItemReceived });
      }
    }
  }
});
