







import Vue from "vue";
import { callableFunctions } from "@/firebase";

import AccountForm from "./AccountForm.vue";
import Modal from "./Modal.vue";

export default Vue.extend({
  name: "AccountCreateModal",
  components: {
    AccountForm,
    Modal
  },
  props: {
    open: { type: Boolean, default: false },
    closeModal: { type: Function, required: true }
  },
  methods: {
    async createAccount({ name, address, phone, email }: Record<string, string>): Promise<void> {
      await callableFunctions.registerAccount({
        email,
        name,
        address,
        phone
      });
      this.closeModal();
    }
  }
});
