











import Vue from "vue";

import QuestionMarkIcon from "@/icons/QuestionMark.vue";

export default Vue.extend({
  name: "Logo",
  components: {
    QuestionMarkIcon
  },
  props: {
    accountId: { type: String, required: true }
  },
  computed: {
    account(): AccountInfo | null {
      return this.$store.direct.state.accounts.all[this.accountId] ?? null;
    },
    title(): string {
      if (this.account?.logo) {
        return `${this.account.name} Logo`;
      }
      return `No logo for ${this.account?.name ?? "unknown account"}`;
    }
  }
});
