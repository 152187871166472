











































import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Upload",
  props: {
    status: {
      type: String as PropType<"invalid" | "disabled" | null>,
      default: null
    }
  }
});
