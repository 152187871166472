























import Vue from "vue";
import orderBy from "lodash/orderBy";
import { db } from "@/firebase";

import ActionButton from "@/components/ActionButton.vue";
import InvitationCodeCreateModal from "@/components/InvitationCodeCreateModal.vue";
import InvitationCodeListItem from "@/components/InvitationCodeListItem.vue";
import ListFirestore from "@/components/ListFirestore.vue";

export default Vue.extend({
  name: "AccountInvitationCodes",
  components: {
    ActionButton,
    InvitationCodeCreateModal,
    InvitationCodeListItem,
    ListFirestore
  },
  data: () => ({
    createModalOpen: false
  }),
  computed: {
    accountId(): string | null {
      return this.$route.params.accountId ?? null;
    }
  },
  methods: {
    closeCreateModal(): void {
      this.createModalOpen = false;
    },
    getInvitationCodeCollection(): FirestoreQuery {
      return db.collection("invitationCodes").where("account", "==", this.accountId);
    },
    orderList(items: Array<unknown>): Array<unknown> {
      return orderBy(Object.values(items), "remainingUses", "desc");
    }
  }
});
