







import Vue from "vue";
import type { QRCodeToDataURLOptions } from "qrcode";

import QRCode from "qrcode";

export default Vue.extend({
  name: "QRCode",
  props: {
    code: { type: String, required: true },
    label: { type: String, default: "QR-Code" }
  },
  data: () => ({
    dataURL: "",
    svgString: ""
  }),
  computed: {
    colorScheme(): "light" | "dark" {
      return this.$store.direct.state.ui.preferredColorScheme;
    }
  },
  watch: {
    colorScheme() {
      this.updateDataUrl();
    }
  },
  mounted() {
    this.updateDataUrl();
  },
  methods: {
    updateDataUrl(): void {
      const isDarkTheme = this.colorScheme === "dark";
      const options: QRCodeToDataURLOptions = {
        scale: 20,
        errorCorrectionLevel: "H",
        color: {
          dark: isDarkTheme ? "#F2F2F2FF" : "#000000FF",
          light: isDarkTheme ? "#212122FF" : "#FFFFFFFF"
        }
      };
      QRCode.toDataURL(this.code, options, (error, url) => {
        this.dataURL = url;
      });
      // QRCode.toString(this.code, { type: "svg" }, (err, svgString) => {
      //   this.dataURL = `data:image/svg+xml;utf8,${base64.encode(svgString)}`;
      // });
    }
  }
});
