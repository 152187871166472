










import Vue, { PropType } from "vue";

import LocationTypeIcon from "./LocationTypeIcon.vue";
import NavigatingButton from "./NavigatingButton.vue";

export default Vue.extend({
  name: "LocationType",
  components: {
    LocationTypeIcon,
    NavigatingButton
  },
  props: {
    locationType: { type: Object as PropType<LocationType | null>, default: null }
  }
});
